import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import './style.scss'

import Accordion from '../accordian'


const RegisterForm = ({ className, redirect, registeredCharityNumber, callback}) => {
  const user = Session.getUserData()
  const charity = Session.getCharityData()
  console.log(charity);

  // let welcomeClass = "welcome-info";
    if(!!className){
      className = "register-form "+className;
    }else{
      className = "register-form";
    }


  let [name, setName] = useState('Lee')
  let [email, setEmail] = useState('user@test.com')
  let [phone, setPhone] = useState('+61 40 3329682')
  let [password, setPassword] = useState('pass')
  let [confirmpassword, setConfirmPassword] = useState('pass')

  const [error, setError] = useState(null)

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    Api.post({
      url: '/api/register',
      data: { name, email, phone, password, confirmpassword, registeredCharityNumber },
    }).then((res) => {
      console.log(res);
      if(!!res){

      if (!res.success || res.error || !res.user) {
        return setError(res.error || 'Invalid credentials')
      }

      Api.post({
        url: '/api/login',
        data: { email, password },
      }).then((res) => {
        if (!res.success || res.error || !res.user) {
          return setError(res.error || 'Invalid credentials')
        }
  
        console.log(res.user)
        Session.setUserData(res.user.user)
        Session.setCharityData(res.user.charity)
  
        
      })

    }



      if(callback){
        callback();
      }
      if(redirect){
        history.push(redirect)
      }
    })
  }

    return (
      <div className={className}>
        {registeredCharityNumber}
        <form id="create-account-form" className="row g-3" style={{margin: "20px",marginBottom: "40px"}} onSubmit={handleSubmit}>

          <Input type="text"  id="name"  value={name} onChange={setName} label={'Your Name'}/>

          <Input type="email" id="email" value={email} onChange={setEmail} label={'Email'} />

          <Input type="phone" id="phone"  value={phone} onChange={setPhone} label={'Phone'}/>

          <Input type="password"  id="password"  value={password} onChange={setPassword} label={'Password'}/>

          <Input type="password"  id="confirmpassword"  value={confirmpassword} onChange={setConfirmPassword} label={'Confirm Password'}/>


          {error && <div className="error">{error}</div>}

          <Button
            className="form__button submit"
            value="Register"
            onClick={handleSubmit}
          />
        </form>
      </div>
    )

}

export default RegisterForm
