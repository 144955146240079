import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Api from 'easy-fetch-api'
import AppWrapper from '../containers/app-wrapper'
import Slider from '../components/slider'
import WelcomeInfo from '../components/welcome-info'
import ProfileForm from '../components/profile-form'

import SliderPanel from '../components/slider-panel'
import QuestionnaireOverviewCard from '../components/questionnaire-overview'
import FinantialsForm from '../components/finantials-form'
import Button from '../components/button'
import Modal from 'react-bootstrap/Modal';

// import '../styles/questionnaire-finantials-page.scss'
import '../styles/pages/questionnaire-transparency.scss'

import { useHistory } from "react-router-dom";


const QuestionnaireTransparencyPage = () => {
  const history = useHistory();

  const fakeImg = {
      color: "white",
      backgroundColor: "grey",
      padding: "10px",
      fontFamily: "Arial",
      // borderRadius: "10px"
      height : "600px",
      width : "600px"
    };

    const customButton={
      title:"Custom Button"
    };

    const inputStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px',
      border: '2px solid #ccc',
      borderRadius: '5px',
    };

    const previewStyle = {
      textAlign: 'center',
      margin: '20px auto',
    };

    const buttonStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px 20px',
      backgroundColor: '#28a745',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    };

    const circle={
      height: "100px",
      width: "100px",
      borderRadius: "50%",
      background: "#aaa"
    }

    const push={
      marginTop:"34%"
    }

    let redirect = false;


    const savedraft = (ev) => {
      ev.preventDefault()

      redirect  = true;
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

    const nextPage = (ev) => {
      ev.preventDefault()

      console.log(ev.target.value);
      console.log(this);

      if(!!ev.target.value){
        // redirect="";
        console.log(document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"]));
        document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"])[0].click();


        if(ev.target.value == "2023"){
          // document.getElementsByClassName(["questionaire-header"])[0].style.display = "none!important"
          setIsVisible(false);
        }
      }

    }

    const wip = (ev) => {
      // ev.preventDefault()


      console.log(ev);
      console.log(this);

      if(redirect){
        console.log("redirect to page...")
        history.push("/");
      }

      // document.getElementById("submit").click();


    }

    const [isVisible, setIsVisible] = useState(true);



    let rightStyle = {
      textAlign: "left"
    }



    function MyVerticallyCenteredModal(props) {
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add supporting link or document
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body closeButton>
          {/* <button type="button" class="btn-close" aria-label="Close" onClick={props.onHide}>X</button> */}


          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none" onClick={props.onHide} className='close-btn'>
  <path d="M13.125 13.125L21.875 21.875M21.875 13.125L13.125 21.875M11.375 30.625H23.625C26.0752 30.625 27.3003 30.625 28.2362 30.1482C29.0594 29.7287 29.7287 29.0594 30.1482 28.2362C30.625 27.3003 30.625 26.0752 30.625 23.625V11.375C30.625 8.92477 30.625 7.69966 30.1482 6.76379C29.7287 5.94058 29.0594 5.27129 28.2362 4.85185C27.3003 4.375 26.0752 4.375 23.625 4.375H11.375C8.92477 4.375 7.69966 4.375 6.76379 4.85185C5.94058 5.27129 5.27129 5.94058 4.85185 6.76379C4.375 7.69966 4.375 8.92477 4.375 11.375V23.625C4.375 26.0752 4.375 27.3003 4.85185 28.2362C5.27129 29.0594 5.94058 29.7287 6.76379 30.1482C7.69966 30.625 8.92477 30.625 11.375 30.625Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            <h2>Add supporting link or document</h2>
            <input type="text" placeholder="Link"/>
            <Button className="button secondary button med">Upload Document</Button>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} className="button primary">Done</Button>
          </Modal.Footer>
        </Modal>
      );
    }

    const [modalShow, setModalShow] = React.useState(false);



  return (
    <AppWrapper className="questionnaire-transparency">
      <div className='header-title'>

        <div className='upper-title'>Charity Index Rating</div>
        <h1>Transparency</h1>
      </div>
      <div className="questionnaire-transparency-page">
        <div className="container">

        {/* {isVisible && <div className="d-flex justify-content-between align-items-center questionaire-header">
          <h1>Finantial Ratios</h1>
          <div className="d-flex align-items-center">
            <button className="btn btn-secondary" onClick={savedraft}>save as draft</button>
          </div>
      </div>} */}


          <Slider className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 ">
          <SliderPanel
            className="col-lg-12"
            >
              <div className="align-middle" >
                <div className="">

                  <h1 style={rightStyle}>Intro</h1>
                  <p style={rightStyle}>
                    While our rating system incorporates financial ratios, it is condidered in conjunction with all other relevent information we gather from you. We recognise that genuine change cannot be accurately assessed solely through numerical metrics.
                  </p>
                  {/* <image src="imgs/logo.png" /> */}
                  <img src="/imgs/hero-2.png" alt=""></img>

                  <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage}>Next</Button>
              </div>
                </div>
              </div>

            </SliderPanel>

            <SliderPanel
            className="col-lg-12"
            >
                <span>1 of 3</span>
                  <h1>General Information</h1>
                  <p>please provide the following amounts for the last 4 years ending 30 June.</p>


                  <div className="upload-question">
                    <label>ACNC updated?</label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml" onClick={() => setModalShow(true)}>Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Reports available on website?</label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml" onClick={() => setModalShow(true)}>Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Audited financial reports?</label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml" onClick={() => setModalShow(true)}>Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Board members listed on website?</label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml" onClick={() => setModalShow(true)}>Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Key staff listed on website?</label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml" onClick={() => setModalShow(true)}>Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>

                  {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button> */}

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} >Next</Button>
              </div>
            </SliderPanel>


            <SliderPanel
            className="col-lg-12"
            >
                <span>3 of 3</span>
                  <h1>Policies</h1>
                  <p>Please provide a link or attachment for each of the following policies. We understand that each organisation may use different names or categories, and one policy may encompass several others. If this applies to your situation, please select ‘yes’ and upload one or multiple documents as required.</p>


                  <div className="upload-question">
                    <label>Privacy policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Whistle-blower policy</label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Conflict of Interest policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Related Part Transactions policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Records Retention and Destruction policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Supplier policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} >Next</Button>
              </div>
            </SliderPanel>




            <SliderPanel
            className="col-lg-12 section-end"
            >
              <div className="align-middle" >
                <div className="section-end">
                  <img src="/imgs/journey.png" />
                  <h1>Nice! You’re 50% of the way through.</h1>
                  <p>
                    Thanks for submitting all your information. You can expect to hear from us in the next X days for your final rating.
                  </p>
                  <div className='d-flex align-items-center justify-content-between'>
                  <Button className="contact-us secondary green" onClick={function(){history.push("/")}}>Back</Button>
                  <Button className="next-module primary" onClick={function(){history.push("/questionnaire-employment")}}>Continue to 3. Employment</Button>
                  </div>
                </div>
              </div>

            </SliderPanel>

          </Slider>
        </div>
      </div>
    </AppWrapper>
  )
}

const getUrl = (url) => Api.get({ url })

export default QuestionnaireTransparencyPage
