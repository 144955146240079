import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import FileUpload from '../file-upload'
import ImageUpload from '../image-upload'



import './style.scss'

import Accordion from '../accordian'



const ProfileFormIntro = ({  children, className, redirect, year, callback, name, location}) => {
  const user = Session.getUserData()
  const charity = Session.getCharityData()

  console.log(charity);


  // let welcomeClass = "welcome-info";
    if(!!charity){
      className = "profile-form-intro "+charity.registeredCharityNumber;
    }else{
      className = "profile-form-intro";
    }


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const [totalrevenue, setTotalRevenue] = useState('100')
  const [totalprogramexpenses, setTotalProgramExpenses] = useState('100')
  const [totalfundraisingexpenses, setTotalFundraisingExpenses] = useState('100')
  const [totalassets, setTotalAssets] = useState('100')
  const [totalliabilities, setTotalLiabilities] = useState('100')
  const [totaldonationsrecived, setTotalDonationsRecived] = useState('100')
  const [totaladministrativeexpenses, setTotalAdministrativeExpenses] = useState('100')
  const [currentassets, setCurrentAssets] = useState('100')
  const [currentliabilities, setCurrentLiabilities] = useState('100')

  const [coverImage, setCoverImg] = useState('')
  const [logo, setCoverLogo] = useState('')
  const [about, setAbout] = useState('')



  // let charityId = "1"
  let editedBy = "default";

  if(!!user){
    editedBy = user._id;
  }


  


  // if(!charity){
  //   charity = "Charity 1";
  // }


  const fetchData = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const response = await fetch('/api/nfp-account-intro/'+charity.registeredCharityNumber);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();


      // setData(result);
      console.log(result);
      setCoverImg(result.charityIntroInfo.coverImage)
      setCoverLogo(result.charityIntroInfo.logo)
      setAbout(result.charityIntroInfo.about)

      // setTotalRevenue(result.financial.totalrevenue)
      // setTotalProgramExpenses(result.financial.totalprogramexpenses)
      // setTotalFundraisingExpenses(result.financial.totalfundraisingexpenses)
      // setTotalAssets(result.financial.totalassets)
      // setTotalLiabilities(result.financial.totalliabilities)
      // setTotalDonationsRecived(result.financial.totaldonationsrecived)
      // setTotalAdministrativeExpenses(result.financial.totaladministrativeexpenses)
      // setCurrentAssets(result.financial.currentassets)
      // setCurrentLiabilities(result.financial.currentliabilities)


    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    console.log("***");
    console.log(user);
    console.log(editedBy);

    let nfpName= "Charity 1";

    console.log();

    console.log(logo);
    console.log(about);

    let registeredCharityNumber = charity.registeredCharityNumber;


    // callback();
    Api.post({
      url: '/api/nfp-account-intro',
      data: { registeredCharityNumber, coverImage, logo, about },
    }).then((res) => {
      if (!res.success || res.error ) {
        return setError(res.error || 'Invalid details')
      }
      callback(res,redirect);
      // history.push(redirect)
    })
  }

  let inputStyle = {};
  let previewStyle = {};
  let buttonStyle = {};


    return (
      <div className={className}>
        <form onSubmit={handleSubmit} id="theForm">
        <div className="row">
        <div className="wrapper">
        <div className="label-error-wrapper">
          <label className="cover-img-label">Add a cover image</label>
        </div>
        <ImageUpload
                uploadUrl="/upload"
                inputStyle={inputStyle}
                previewStyle={previewStyle}
                buttonStyle={buttonStyle}
                className="cover-img"
                onChange={setCoverImg}
                value={coverImage}
              />
</div>
<div className="wrapper logo">

<div className="label-error-wrapper">
          <label className="logo-label">Add your logo</label>
        </div>
              <ImageUpload
                uploadUrl="/upload"
                inputStyle={inputStyle}
                previewStyle={previewStyle}
                buttonStyle={buttonStyle}
                className="logo"
                onChange={setCoverLogo}
                value={logo}
              />
</div>



<div className="col-lg-12 profile-summary">
  <h2>{name}</h2>
  <p>{location}</p>
</div>

<Input type="textarea" value={about} onChange={setAbout} label={'About the organisation'} rows="10" />

            {/* <Input type="text" value={totalrevenue} onChange={setTotalRevenue} label={'Total revenue'} />
            <Input type="text" value={totalprogramexpenses} onChange={setTotalProgramExpenses} label={'Total program expenses'} />
            <Input type="text" value={totalfundraisingexpenses} onChange={setTotalFundraisingExpenses} label={'Total fundraising expenses'} />
            <Input type="text" value={totalassets} onChange={setTotalAssets} label={'Total assets'} />
            <Input type="text" value={totalliabilities} onChange={setTotalLiabilities} label={'Total liabilities'} />

            <Input type="text" value={totaldonationsrecived} onChange={setTotalDonationsRecived} label={'Total donations recived'} />
            <Input type="text" value={totaladministrativeexpenses} onChange={setTotalAdministrativeExpenses} label={'Total administrative expenses'} />
            <Input type="text" value={currentassets} onChange={setCurrentAssets} label={'Current assets'} />
            <Input type="text" value={currentliabilities} onChange={setCurrentLiabilities} label={'Current liabilities'} />

            <Input type="text" value={currentliabilities} onChange={setCurrentLiabilities} label={'Surplus or Deficit this year'} />


            <div className='checkbox'>
              <input type="checkbox" value="Surpus" onChange={setCurrentLiabilities} label={'Surpus'} />
              <label>Surpus</label>
            </div>
            <div className='checkbox'>
              <input type="checkbox" value="Deficit" onChange={setCurrentLiabilities} label={'Deficit'} />
              <label>Deficit</label>
            </div> */}





            {/* <FileUpload /> */}

<button className="submit" onClick={handleSubmit} > submit </button>
        </div>
        </form>
      </div>
    )

}

export default ProfileFormIntro
