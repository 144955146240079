import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import WelcomePage from './pages/welcome'
import LandingPage from './pages/index'
import AdminPage from './pages/admin'
import LoginPage from './pages/login'
import RegisterNfpPage from './pages/register-nfp'
import SetupProfilePage from './pages/setup-profile'
import ComponentsPage from './pages/components'
import QuestionnairePage from './pages/questionnaire'
import QuestionnaireFinantialsPage from './pages/questionnaire-finantials'
import QuestionnaireTransparencyPage from './pages/questionnaire-transparency'
import QuestionnaireEmploymentPage from './pages/questionnaire-employment'
import QuestionnaireAdaptabilityPage from './pages/questionnaire-adaptability'
import DashboardPage from './pages/dashboard'
import LandingV1Page from './pages/landing-v1'
import SetupProjectPage from './pages/setup-project'
import ProjectPage from './pages/project'


import NfpProfilePage from './pages/nfp-profile'


import Session from './components/session'
import ROLES from './common/roles'
import { history } from './components/history'
const userData = Session.getUserData()
  const isLoggedIn = !!userData
const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const userData = Session.getUserData()
  const isLoggedIn = !!userData
  const role = userData ? userData.role : null

  console.log(isLoggedIn);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          allowedRoles.indexOf(role) > -1 ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          // <Redirect to="/login" />
          <Redirect to="/" />
        )
      }
    />
  )
}

ReactDOM.render(
  <Router history={history}>
    <Switch>
      {/* {!!isLoggedIn ? (
        <Route path="/" exact component={DashboardPage} className="landing-page" />
      ):( */}
        <Route path="/" exact component={LandingV1Page} className="landing-page" />
      {/* )} */}
      <Route path="/welcome" exact component={WelcomePage} />
      <Route path="/login" exact component={LoginPage} />
      <PrivateRoute path="/components" exact component={ComponentsPage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <Route path="/register-nfp" exact component={RegisterNfpPage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/setup-profile" exact component={SetupProfilePage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/questionnaire" exact component={QuestionnairePage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/questionnaire-finantials" exact component={QuestionnaireFinantialsPage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/questionnaire-transparency" exact component={QuestionnaireTransparencyPage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/questionnaire-employment" exact component={QuestionnaireEmploymentPage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/questionnaire-adaptability" exact component={QuestionnaireAdaptabilityPage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/dashboard" exact component={DashboardPage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/landing" exact component={LandingV1Page} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/nfp-profile" exact component={NfpProfilePage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      <PrivateRoute path="/setup-project" exact component={SetupProjectPage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>

      <PrivateRoute path="/project/:id" exact component={ProjectPage} allowedRoles={[ROLES.USER, ROLES.ADMIN]}/>
      
      <PrivateRoute
        path="/admin"
        exact
        component={AdminPage}
        allowedRoles={[ROLES.ADMIN]}
      />
      <PrivateRoute
        path="/user"
        exact
        component={AdminPage}
        allowedRoles={[ROLES.USER, ROLES.ADMIN]}
      />
    </Switch>
  </Router>,
  document.getElementById('root')
)
