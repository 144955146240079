import React, { useState } from 'react';
import axios from 'axios';

import './style.scss'



const FileUpload = ({ uploadUrl, inputStyle, previewStyle, buttonStyle }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // setPreview(URL.createObjectURL(file));
    handleUpload();
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploadedUrl(response.data.url); // Assuming the response contains the URL
      setSelectedFile(null);
      setPreview(null);
      setUploading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploading(false);
    }
  };


  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    setSelectedFile(droppedFile);
    handleUpload();
  };

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setFile(selectedFile);
  // };

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <div
      className="file-upload"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      {uploadedUrl ? (
        <div>
          <p>{uploadedUrl}</p>
        </div>
      ) : (
        <div className="upload-prompt">
          <p>
            <strong>Click to upload</strong> or drag and drop
          </p>
          <p>PDF recommended</p>
        </div>
      )}
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );

  

  // return (
  //   <div>
  //     <input
  //       type="file"
  //       accept="*"
  //       onChange={handleFileChange}
  //       style={inputStyle}
  //     />
  //     <button onClick={handleUpload} disabled={uploading} style={buttonStyle}>
  //       {uploading ? 'Uploading...' : 'Upload'}
  //     </button>
  //     {uploadedUrl && (
  //       <div className="uploaded-url">
  //         <a href={uploadedUrl} target="_blank" rel="noopener noreferrer">
  //           {uploadedUrl}
  //         </a>
  //       </div>
  //     )}
  //   </div>
  // );
};

export default FileUpload;


