import React from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header-nomenu'
import Footer from '../components/footer'
import Menu from '../components/menu'

import '../styles/charity-wrapper.scss'




function App({ children,className }) {
  return (
    <>
    <div className={className}>
      <span className="divide-line" ></span>
      <div className="app">
        <Header />
        <Menu />
        <main className="main" id="main">{children}</main>
      </div>
    </div>
    <Footer />
    </>
  )
}

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default App
