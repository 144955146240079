import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Api from 'easy-fetch-api'
import AppWrapper from '../containers/app-wrapper'
import Slider from '../components/slider'
import WelcomeInfo from '../components/welcome-info'
import ProfileForm from '../components/profile-form'

import ProjectFormInfo from '../components/project-form-info'
import ProjectFormImpact from '../components/project-form-impact'
import ProjectFormFaqs from '../components/project-form-faqs'
import ProjectFormSettings from '../components/project-form-settings'


import SliderPanel from '../components/slider-panel'
import ImageUpload from '../components/image-upload'

import '../styles/pages/setup-project.scss'

import Button from '../components/button'

import { useHistory } from "react-router-dom";

const SetupProjectPage = () => {

  const history = useHistory();
  let redirect = false;
  let editState = false;
  let header = "Add a Project";
  let upperHeader = "Get Started";

  
  let goto = "/";

  if(window.location.hash === "#edit"){
    editState = true;
    header = "Edit Project";
    upperHeader = "";
  }

  const [isVisible, setIsVisible] = useState(editState);
  const [projectId, setprojectId] = useState();
  const [projectPageUrl, setprojectPageUrl] = useState();

  


    const savedraft = (ev) => {
      ev.preventDefault()

      redirect  = true;
      goto = "/";
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

    const nextPage = (ev) => {
      ev.preventDefault()

      console.log(ev.target.value);
      console.log(this);

      if(!!ev.target.value){
        // redirect="";
        console.log(document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"]));
        document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"])[0].click();


        if(ev.target.value == "2023"){
          // document.getElementsByClassName(["questionaire-header"])[0].style.display = "none!important"
          setIsVisible(false);
        }
      }

    }

    const wip = (ev, redirected) => {
      // ev.preventDefault()


      console.log(ev);
      console.log(this);
      console.log(redirected);

      if(!!ev.project){
        setprojectId(ev.project._id);
        setprojectId("/project/"+ev.project._id);
      }

      if(redirect){
        console.log("redirect to page...")
        history.push(goto);
      }

      // document.getElementById("submit").click();


    }
    

    const preview = (ev) => {
      ev.preventDefault()

      redirect  = true;
      goto = "/nfp-profile";
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

    const done = (ev) => {
      ev.preventDefault()

      redirect  = true;
      goto = "/project/"+projectId;
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

  return (
    <AppWrapper className="setup-project">
      <div className='header-title'>
        <div className='upper-title'>{upperHeader}</div>
        <h1>{header}</h1>
      </div>


      <div className="setup-project-page">
        <div className="container">
          <Slider className="section register  d-flex flex-column align-items-center justify-content-center " redirect={projectPageUrl} hideSlide={isVisible}>


            <SliderPanel className="row intro" >

            <div className="col-lg-8">
              <span>1 of 4</span>
              <h2>Project info</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              <div className="row">
                <ProjectFormInfo  callback={wip} name="The Smith Family" location="Australia" projectId={projectId}/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="secondary lrg" onClick={savedraft}>Save as draft</Button>
                <Button className="secondary lrg" onClick={preview}>Preview</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="intro">Next</Button>
              </div>
            </div>
            </SliderPanel>

            <SliderPanel className="row story" >

            <div className="col-lg-8">
              <span>2 of 4</span>
              <h2>Project Goals and Impact</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              <div className="row">
                <ProjectFormImpact redirect={redirect} callback={wip} name="The Smith Family" location="Australia" projectId={projectId}/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="secondary lrg" onClick={savedraft}>Save as draft</Button>
                <Button className="secondary lrg"onClick={preview}>Preview</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="story">Next</Button>
              </div>
            </div>
            </SliderPanel>

            <SliderPanel className="row depth" >

              <div className="col-lg-8">
                <span>3 of 4</span>
                <h2>FAQs</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                <div className="row">
                  <ProjectFormFaqs  redirect={redirect} callback={wip} name="The Smith Family" location="Australia" projectId={projectId}/>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <Button className="secondary prev-btn lrg">back</Button>
                  <Button className="secondary lrg" onClick={savedraft}>Save as draft</Button>
                  <Button className="secondary lrg"onClick={preview}>Preview</Button>
                  <Button className="primary button next-btn lrg" onClick={nextPage} value="depth">Next</Button>
                </div>
              </div>
            </SliderPanel>

            <SliderPanel className="row depth" >

              <div className="col-lg-8">
                <span>4 of 4</span>
                <h2>Project Settings</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                <div className="row">
                  <ProjectFormSettings redirect={redirect} callback={wip} name="The Smith Family" location="Australia" projectId={projectId}/>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <Button className="secondary prev-btn lrg">back</Button>
                  <Button className="secondary lrg" onClick={savedraft}>Save as draft</Button>
                  <Button className="secondary lrg"onClick={preview}>Preview</Button>
                  <Button className="primary button next-btn lrg" onClick={done} value="depth">Next</Button>
                </div>
              </div>
            </SliderPanel>






          </Slider>
        </div>
      </div>
    </AppWrapper>
  )
}

const getUrl = (url) => Api.get({ url })

export default SetupProjectPage
