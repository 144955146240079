import React from 'react'
import { Link } from 'react-router-dom'
import Api from 'easy-fetch-api'
import AppWrapper from '../containers/charity-app-wrapper'

import Dash from '../components/dash'
import Projects from '../components/projects'
import Reports from '../components/reports'
import Partners from '../components/partners'

import GetIndex from '../components/get-index'

import { Line } from 'react-chartjs-2';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../styles/dashboard-page.scss'

const DashboardPage = () => {

  const data = {
    labels: ['Jul', 'Sep', 'Nov', 'Jan', 'Mar', 'May'],
    datasets: [
      {
        label: 'Total Donations',
        data: [0, 10, 30, 20, 40, 45],
        fill: false,
        borderColor: '#3e95cd'
      }
    ]
  };

  return (
    <AppWrapper  className="dashboard container">
      
      <Dash />
      <GetIndex />
      
      <Container className="donations">

        <Row className="my-4">
          <Col>
            <h2>Total Donations</h2>
            <Line data={data} />
          </Col>
        </Row>
      </Container>

      <Projects />

      <div className="row">
        <div className="col-lg-6">
          <Reports />  
        </div>  
        <div className="col-lg-6">
          <Partners />  
        </div>  
      </div>
      
    </AppWrapper>
  )
}

const getUrl = (url) => Api.get({ url })

export default DashboardPage
