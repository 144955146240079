import React from 'react'
import { history } from '../history'
import Session from '../session'
import './style.scss'

const Header = ({className}) => {

  if(!!className){
    className = "sidebar "+className;
  }else{
    className = "sidebar";
  }

  
  const user = Session.getUserData()

  const logout = () => {
    Session.logout()
    history.push('/')
  }

  return (
  <aside id="sidebar" className={className}>

<ul className="sidebar-nav" id="sidebar-nav">

  <li className="nav-item">
    <a className="nav-link active" href="/dashboard">
      <i className="bi bi-grid"></i>
      <span>Dashboard</span>
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link " href="/dashboard">
      <i className="bi bi-grid"></i>
      <span>Projects</span>
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/dashboard">
      <i className="bi bi-grid"></i>
      <span>Donations</span>
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/dashboard">
      <i className="bi bi-grid"></i>
      <span>Reports</span>
    </a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/dashboard">
      <i className="bi bi-grid"></i>
      <span>Partners</span>
    </a>
  </li>


</ul>

</aside>      
  )
}

export default Header
