import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './style.scss'


const ImageUpload = ({ className, uploadUrl, inputStyle, previewStyle, buttonStyle, onChange, value }) => {
  // const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(value);
  // const [upStyle, setUpStyle] = useState({
  //   background:value,
  //   border:"2pc solid #000"
  // });

  const handleFileChange = (event) => {
    console.log("handleFileChange",event)
    console.log("handleFileChange",this)

    console.log("file: ",event.currentTarget.files)
    let file = event.target.files[0];
    
    console.log("file: ",file)
    if(file==null){
      file = event.currentTarget.files[0];
    }

    // setSelectedFile(file);
    setPreview(URL.createObjectURL(file));
    handleUpload(file);
  };

  let inputId = Math.floor(Math.random() * 999999999999)+"-form-click";



  if(!!className){
    className = "image-upload "+className;
  }else{
    className = "image-upload";
  }

  if(!!uploadedUrl){
    className = className+" populated";

  }

  const handleClick = () => {
    document.getElementById(inputId).click();
    // let imgsel = document.querySelector(className);
    // imgsel.querySelector('input').click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    // setSelectedFile(droppedFile);
    handleUpload(droppedFile);
  };


  const handleUpload = async (selectedFile) => {

    console.log(selectedFile);
    // console.log(newSelectedFile);

    if (!selectedFile) return;

    setUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploadedUrl(response.data.url); // Assuming the response contains the URL
      
      onChange(response.data.url);
      // setSelectedFile(null);
      setPreview(null);
      setUploading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploading(false);
    }
  };

  useEffect(() => {
    // if(value!=null){
      setUploadedUrl(value);
      // setUpStyle({
      //   background:"url("+value+")",
      //   border:"2pc solid #000"
      // });
    // }
    console.log("value change",value)
  }, [value]);

  let upStyle = {
    backgroundImage:"url("+uploadedUrl+")",
    // backgroundSize: "100%",
    // // backgroundSize: "50%",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center"
  }

  return (
    <div className={className}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
      style={upStyle}
>

      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        id={inputId}
        // style={inputStyle}
        // style={{ display: 'none' }}
      />


      {uploadedUrl ? (
        <div className="upload-prompt">
          <p>
            <strong>Click to upload</strong> or drag and drop
          </p>
          <p>JPG recommended</p>
        </div>
      ) : (
        <div className="upload-prompt">
          <p>
            <strong>Click to upload</strong> or drag and drop
          </p>
          <p>JPG recommended</p>
        </div>
      )}
      {/* <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      /> */}



      {/* {preview && (
        <div className="preview" style={previewStyle}>
          <img src={preview} alt="Preview" />
        </div>
      )}
      <button onClick={handleUpload} disabled={uploading} style={buttonStyle}>
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
      {uploadedUrl && (
        <div className="uploaded-url">
          <a href={uploadedUrl} target="_blank" rel="noopener noreferrer">
            {uploadedUrl}
          </a>
        </div>
      )} */}
    </div>
  );
};

export default ImageUpload;
