import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Api from 'easy-fetch-api'
import AppWrapper from '../containers/app-wrapper'
import Slider from '../components/slider'
import WelcomeInfo from '../components/welcome-info'
import SliderPanel from '../components/slider-panel'
import Input from '../components/input'


import RegisterNfpForm from '../components/register-nfp'
import RegisterForm from '../components/register-form'

import Button from '../components/button'

import '../styles/pages/register-nfp.scss'

import { useHistory } from "react-router-dom";

const RegisterNfpPage = () => {
  const history = useHistory();

  let [registeredCharityNumber, setRegisteredCharityNumber] = useState('')


  const mystyle = {
      color: "white",
      backgroundColor: "DodgerBlue",
      padding: "10px",
      fontFamily: "Arial"
    };

    let redirect = false;


    const savedraft = (ev) => {
      ev.preventDefault()

      redirect  = true;
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

    const preview = (ev) => {
      ev.preventDefault()

      redirect  = true;
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }



    const nextPage = (ev) => {
      ev.preventDefault()

      console.log(ev.target.value);
      console.log(this);

      if(!!ev.target.value){
        // redirect="";
        console.log(document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"]));
        document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"])[0].click();


        if(ev.target.value == "2023"){
          // document.getElementsByClassName(["questionaire-header"])[0].style.display = "none!important"
          setIsVisible(false);
        }
      }

    }

    const wip = (ev) => {
      // ev.preventDefault()


      console.log(ev);
      console.log(this);

      if(!!ev){
        if(!!ev.charity){
          if(!!ev.charity.registeredCharityNumber){
            setRegisteredCharityNumber(ev.charity.registeredCharityNumber);
          }
        }
      }
      
      if(redirect){
        console.log("redirect to page...")
        history.push("/");
      }

      // document.getElementById("submit").click();


    }
    const [isVisible, setIsVisible] = useState(true);



  return (
    <AppWrapper  className="register-nfp">
      <div className='header-title'>
        <div className='upper-title'>Get Started</div>
        <h1>Create Account</h1>
      </div>


      <div className="welcome-page">
        <div className="container">
        <Slider className="section register d-flex flex-column align-items-center justify-content-center" redirect="/setup-profile">
            <SliderPanel className="col-lg-12 nfp" >


              <span>1 of 3</span>
              <h2>Set up your Not-For-Profit</h2>
              <p>Please provide the following details to create your Cascade profile.</p>

              <div className="row">
                <RegisterNfpForm title="Finantial Ratios" progress={30} buttonLink="/finantial-ratios" year="2021" redirect={redirect} callback={wip}/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="nfp">Next</Button>
              </div>
            </SliderPanel>

            <SliderPanel className="col-lg-12 account" >


              <span>2 of 3</span>
              <h2>Set up your account</h2>
              <p>Please provide the following details. These will not be public.</p>

              <div className="row">
                <RegisterForm title="Account" progress={30} buttonLink="/finantial-ratios" year="2021" redirect={redirect} callback={wip} registeredCharityNumber={registeredCharityNumber}/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="account">Next</Button>
              </div>
            </SliderPanel>

            <SliderPanel className="col-lg-12 confirm" >


              <span>3 of 3</span>
              <h2>Confirm account</h2>
              <p>Please provide the code sent to your email.</p>

              <div className="row">
                <Input type="text"  id="code"   label={'Code'}/>

              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage}>Next</Button>
              </div>
            </SliderPanel>

            <SliderPanel className="col-lg-12 finish" >


              <span>3 of 3</span>
              <h2>Account created</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              <div className="row">
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" >Finish your profile</Button>
              </div>
            </SliderPanel>



              {/* <RegisterNfpForm redirect="/"/> */}

          </Slider>
        </div>
      </div>
    </AppWrapper>
  )
}

const getUrl = (url) => Api.get({ url })

export default RegisterNfpPage
