import React from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem, Image } from 'react-bootstrap';
import './style.scss'
import Button from '../button';

const partnerData = [
  { name: 'Optus', donated: '$12,768 donated', avatar: '/imgs/logo.png' },
  { name: 'Canva', donated: '$5,023 donated', avatar: '/imgs/logo.png' },
  { name: 'Atlassian', donated: '$4,005 donated', avatar: '/imgs/logo.png' },
  { name: 'Bebo', donated: '$2,318 donated', avatar: '/imgs/logo.png' }
];
let nopad={
  padding:"0px"
}
const Partners = () => {
  return (
    <Container style={nopad}>
      {/* <Row className="my-4"> */}
          <h2>Partners</h2>
          <ListGroup>
            {partnerData.map((partner, index) => (
              <ListGroupItem key={index}>
                <div className='content'>
                  
                  <div className="name"><Image src={partner.avatar} roundedCircle width={50} height={50} className="me-3" /><b>{partner.name}</b></div>
                  <span>{partner.donated}</span>
                  <Button className="secondary sml green">View</Button>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
      {/* </Row> */}
    </Container>
  );
};

export default Partners;
