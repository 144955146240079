import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import './style.scss'

import Accordion from '../accordian'


const RegisterNfpForm = ({ children, className, redirect, callback}) => {
  const user = Session.getUserData()

  // let welcomeClass = "welcome-info";
    if(!!className){
      className = "register-nfp-form "+className;
    }else{
      className = "register-nfp-form";
    }

    if(user){
      console.log("user:",user._id);

      
    }
    
  // let [nfpName, setNfpName] = useState('Charity 1')
  // let [companySize, setCompanySize] = useState('1000')
  // let [companySector, setCompanySector] = useState('Tech')
  // let [location, setLocation] = useState('Sydney')
  // let [website, setWebsite] = useState('website')
  // let [phone, setPhone] = useState('+61 40 3329682')




  // let [registeredName, setregisteredName] = useState('a')
  // let [registeredCharityNumber, setregisteredCharityNumber] = useState('123')
  // let [abnAcn, setabnAcn] = useState('123')
  // let [companySize, setcompanySize] = useState('1')
  // let [companySector, setcompanySector] = useState('a')
  // let [location, setlocation] = useState('a')
  // let [website, setwebsite] = useState('a')
  // let [registeredCompanyAddress, setregisteredCompanyAddress] = useState('a')
  // let [contactName, setcontactName] = useState('a')
  // let [contactEmail, setcontactEmail] = useState('a')

  let [registeredName, setregisteredName] = useState("")
  let [registeredCharityNumber, setregisteredCharityNumber] = useState("")
  let [abnAcn, setabnAcn] = useState("")
  let [companySize, setcompanySize] = useState("")
  let [companySector, setcompanySector] = useState("")
  let [location, setlocation] = useState("")
  let [website, setwebsite] = useState("")
  let [registeredCompanyAddress, setregisteredCompanyAddress] = useState("")
  let [contactName, setcontactName] = useState("")
  let [contactEmail, setcontactEmail] = useState("")




  // let userId = user._id;


  const [error, setError] = useState(null)

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    Api.post({
      url: '/api/register-nfp',
      data: { registeredName,registeredCharityNumber,abnAcn,companySize,companySector,location,website,registeredCompanyAddress,contactName,contactEmail },
    }).then((res) => {
      console.log(res);
      Session.setCharityData(res.charity)
      if(!!res){
      if (!res.success || res.error || !res.charity) {
        callback(null);
        return setError(res.error || 'Invalid credentials')
      }
    }
      callback(res);
      // history.push(redirect)
    })
  }

    return (
      <div className={className}>
        <form id="create-account-form" className="row g-3" style={{margin: "20px",marginBottom: "40px"}} onSubmit={handleSubmit}>


        <Input type="text"  id="registeredName" value={registeredName} onChange={setregisteredName} label={'registeredName'}/>
        <Input type="text"  id="registeredCharityNumber" value={registeredCharityNumber} onChange={setregisteredCharityNumber} label={'registeredCharityNumber'}/>
        <Input type="text"  id="abnAcn" value={abnAcn} onChange={setabnAcn} label={'abnAcn'}/>
        <Input type="text"  id="companySize" value={companySize} onChange={setcompanySize} label={'companySize'}/>
        <Input type="text"  id="companySector" value={companySector} onChange={setcompanySector} label={'companySector'}/>
        <Input type="text"  id="location" value={location} onChange={setlocation} label={'location'}/>
        <Input type="text"  id="website" value={website} onChange={setwebsite} label={'website'}/>
        <Input type="text"  id="registeredCompanyAddress" value={registeredCompanyAddress} onChange={setregisteredCompanyAddress} label={'registeredCompanyAddress'}/>
        <Input type="text"  id="contactName" value={contactName} onChange={setcontactName} label={'contactName'}/>
        <Input type="text"  id="contactEmail" value={contactEmail} onChange={setcontactEmail} label={'contactEmail'}/>





          {error && <div className="error">{error}</div>}

          <Button
            className="form__button submit"
            value="Register"
            onClick={handleSubmit}
          />
        </form>
      </div>
    )

}

export default RegisterNfpForm
