import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const projectData = [
  { title: 'Christmas Appeal', amount: '$1,502 raised (40%)', image: '/imgs/project-default.png' },
  { title: 'Christmas Appeal', amount: '$1,502 raised (78%)', image: '/imgs/project-default.png' },
  { title: 'Christmas Appeal', amount: '$1,502 raised (16%)', image: '/imgs/project-default.png' }
];

const Projects = () => {
  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h2>Projects</h2>
        </Col>
      </Row>
      <Row>
        {projectData.map((project, index) => (
          <Col md={4} key={index}>
            <Card className="mb-4">
              <Card.Img variant="top" src={project.image} />
              <Card.Body>
                <Card.Title>{project.title}</Card.Title>
                <Card.Text>{project.amount}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Projects;
