import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Api from 'easy-fetch-api'
import AppWrapper from '../containers/app-wrapper'
import Slider from '../components/slider'
import WelcomeInfo from '../components/welcome-info'
import ProfileForm from '../components/profile-form'
import ProfileFormIntro from '../components/profile-form-intro'
import ProfileFormIndepth from '../components/profile-form-indepth'
import ProfileFormStory from '../components/profile-form-story'
import Project from '../components/project'
import Session from '../components/session'


import SliderPanel from '../components/slider-panel'
import ImageUpload from '../components/image-upload'
import '../styles/pages/nfp-profile.scss'
import Button from '../components/button'

import { useHistory } from "react-router-dom";

const NfpProfilePage = () => {

  const history = useHistory();
  let redirect = false;
  const charity = Session.getCharityData()

  let [registeredName, setregisteredName] = useState('Lee')
  let [registeredCharityNumber, setregisteredCharityNumber] = useState('Lee')
  let [abnAcn, setabnAcn] = useState('Lee')
  let [companySize, setcompanySize] = useState('Lee')
  let [companySector, setcompanySector] = useState('Lee')
  let [location, setlocation] = useState('Lee')
  let [website, setwebsite] = useState('Lee')
  let [registeredCompanyAddress, setregisteredCompanyAddress] = useState('Lee')
  let [contactName, setcontactName] = useState('Lee')
  let [contactEmail, setcontactEmail] = useState('Lee')
  let [logo, setlogo] = useState('Lee')
  let [coverImage, setcoverImage] = useState('Lee')
  let [about, setabout] = useState('Lee')
  let [mission, setmission] = useState('Lee')
  let [companyValue1, setcompanyValue1] = useState('Lee')
  let [companyValue2, setcompanyValue2] = useState('Lee')
  let [companyValue3, setcompanyValue3] = useState('Lee')
  let [companyValue4, setcompanyValue4] = useState('Lee')
  let [companyValue5, setcompanyValue5] = useState('Lee')
  let [companyValue6, setcompanyValue6] = useState('Lee')
  let [image1, setimage1] = useState('Lee')
  let [image2, setimage2] = useState('Lee')
  let [image3, setimage3] = useState('Lee')
  let [image4, setimage4] = useState('Lee')
  let [image5, setimage5] = useState('Lee')
  let [image6, setimage6] = useState('Lee')
  let [otherReports, setotherReports] = useState('Lee')
  let [otherDocuments, setotherDocuments] = useState('Lee')


  console.log(charity);

  const fetchData = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const response = await fetch('/api/nfp/'+charity.registeredCharityNumber);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();


      // setData(result);
      console.log(result);

        
      setregisteredName(result.charity.registeredName)
      setregisteredCharityNumber(result.charity.registeredCharityNumber)
      setabnAcn(result.charity.abnAcn)
      setcompanySize(result.charity.companySize)
      setcompanySector(result.charity.companySector)
      setlocation(result.charity.location)
      setwebsite(result.charity.website)
      setregisteredCompanyAddress(result.charity.registeredCompanyAddress)
      setcontactName(result.charity.contactName)
      setcontactEmail(result.charity.contactEmail)
      setlogo(result.charity.logo)
      setcoverImage(result.charity.coverImage)
      setabout(result.charity.about)
      setmission(result.charity.mission)
      setcompanyValue1(result.charity.companyValue1)
      setcompanyValue2(result.charity.companyValue2)
      setcompanyValue3(result.charity.companyValue3)
      setcompanyValue4(result.charity.companyValue4)
      setcompanyValue5(result.charity.companyValue5)
      setcompanyValue6(result.charity.companyValue6)
      setimage1(result.charity.image1)
      setimage2(result.charity.image2)
      setimage3(result.charity.image3)
      setimage4(result.charity.image4)
      setimage5(result.charity.image5)
      setimage6(result.charity.image6)
      setotherReports(result.charity.otherReports)
      setotherDocuments(result.charity.otherDocuments)


    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fakeImg = {
      color: "white",
      backgroundColor: "grey",
      padding: "10px",
      fontFamily: "Arial",
      // borderRadius: "10px"
      height : "600px",
      width : "600px"
    };

    const customButton={
      title:"Custom Button"
    };

    const inputStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px',
      border: '2px solid #ccc',
      borderRadius: '5px',
    };

    const previewStyle = {
      textAlign: 'center',
      margin: '20px auto',
    };

    const buttonStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px 20px',
      backgroundColor: '#28a745',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    };


    const savedraft = (ev) => {
      ev.preventDefault()

      redirect  = true;
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

    const nextPage = (ev) => {
      ev.preventDefault()

      console.log(ev.target.value);
      console.log(this);

      if(!!ev.target.value){
        // redirect="";
        console.log(document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"]));
        document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"])[0].click();


        if(ev.target.value == "2023"){
          // document.getElementsByClassName(["questionaire-header"])[0].style.display = "none!important"
          setIsVisible(false);
        }
      }

    }

    const wip = (ev) => {
      // ev.preventDefault()


      console.log(ev);
      console.log(this);

      if(redirect){
        console.log("redirect to page...")
        history.push("/");
      }

      // document.getElementById("submit").click();


    }

    const clickDone = (ev) => {
      history.push("/dashboard");
    }
    const clickEdit = (ev) => {
      history.push("/setup-profile#edit");
    }
      
    const [isVisible, setIsVisible] = useState(true);

    let previewControls = true;


    let heroStyle = {
      backgroundImage: "url("+coverImage+")"
    }

    let image1Style = {
      backgroundImage: "url("+image1+")"
    }
    let image2Style = {
      backgroundImage: "url("+image2+")"
    }
    let image3Style = {
      backgroundImage: "url("+image3+")"
    }
    let image4Style = {
      backgroundImage: "url("+image4+")"
    }
    let image5Style = {
      backgroundImage: "url("+image5+")"
    }
    let image6Style = {
      backgroundImage: "url("+image6+")"
    }
 
  return (
    <AppWrapper className="nfp-profile">
      {!!previewControls ? (
        <div className="preview-controls d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 d-flex align-items-center justify-content-between">
                <p>Your viewing a preivew of your page, all good?</p>
                <div className="d-flex align-items-center justify-content-between">
                  <Button  className="button secondary" onClick={clickEdit}>Continue Editing</Button>
                  <Button className="button primary done" onClick={clickDone}>Done</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ):<div></div>}


      <div className='header-title' style={heroStyle}>
        <img src={coverImage} />
      </div>


      <div className="nfp-profile-page">
        <div className="container">

          <div className="row title">
            <div className="col-lg-3">
              <Button className="button secondary">Favorite</Button>
            </div>
            <div className="col-lg-6">
              <h1>{registeredName}</h1>
              <p>{about}</p>
              <div className="index-score">
                <div className='score'>
                  100
                </div>
                <b>Charity Index Score</b><br />
                <a>What does this mean?</a>
              </div>
            </div>
            <div className="col-lg-3">
              <Button className="button primary">Add as Partner</Button>
            </div>
          </div>
          
          <div className="row mission">
          <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <h1>Our mission and story</h1>
              <p>{mission}</p>
            </div>
            <div className="col-lg-6 icons">
              <div className='row'>
                <div className="col-lg-6">
                  <div className='icon d-flex align-items-center'>
                    <div className='svg'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.7778 8C10.1512 8 6 13.4678 6 18.96C6 21.6029 7.02627 24.2413 8.73593 26.79C10.444 29.3363 12.7626 31.6842 15.1556 33.701C17.541 35.7115 19.9461 37.3477 21.7773 38.4806C22.6915 39.0462 23.454 39.481 23.9887 39.7697C23.9925 39.7717 23.9962 39.7737 24 39.7758C24.0038 39.7737 24.0075 39.7717 24.0113 39.7697C24.546 39.481 25.3085 39.0462 26.2227 38.4806C28.0539 37.3477 30.459 35.7115 32.8444 33.701C35.2374 31.6842 37.556 29.3363 39.2641 26.79C40.9737 24.2413 42 21.6029 42 18.96C42 13.4678 37.8488 8 32.2222 8C28.9397 8 26.807 9.63647 25.5225 11.1444C25.1425 11.5905 24.586 11.8475 24 11.8475C23.414 11.8475 22.8575 11.5905 22.4775 11.1444C21.193 9.63647 19.0603 8 15.7778 8ZM2 18.96C2 11.9422 7.31548 4 15.7778 4C19.4319 4 22.1322 5.42101 24 7.02165C25.8678 5.42101 28.5681 4 32.2222 4C40.6845 4 46 11.9422 46 18.96C46 22.6509 44.5707 26.0594 42.5859 29.0182C40.5996 31.9795 37.9848 34.5998 35.4222 36.7596C32.8521 38.9257 30.2795 40.6744 28.3273 41.8822C27.3498 42.4869 26.5193 42.9614 25.9116 43.2895C25.6093 43.4526 25.3525 43.5849 25.1543 43.6808C25.0578 43.7274 24.9574 43.7742 24.8638 43.8135C24.8196 43.8321 24.7518 43.8597 24.6737 43.8862C24.6354 43.8993 24.5709 43.9201 24.4912 43.9398L24.4898 43.9402C24.4391 43.9527 24.2475 44 24 44C23.7525 44 23.5609 43.9527 23.5102 43.9402L23.5088 43.9398C23.4291 43.9201 23.3646 43.8993 23.3263 43.8862C23.2482 43.8597 23.1804 43.8321 23.1362 43.8135C23.0426 43.7742 22.9422 43.7274 22.8457 43.6808C22.6475 43.5849 22.3907 43.4526 22.0884 43.2895C21.4807 42.9614 20.6502 42.4869 19.6727 41.8822C17.7205 40.6744 15.1479 38.9257 12.5778 36.7596C10.0152 34.5998 7.40045 31.9795 5.41407 29.0182C3.42928 26.0594 2 22.6509 2 18.96Z" fill="#EE6D21"/>
                      </svg>
                    </div>
                    <p>Care</p>
                  </div>
                </div>
                <div className="col-lg-6">
                <div className='icon d-flex align-items-center'>
                    <div className='svg'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.7778 8C10.1512 8 6 13.4678 6 18.96C6 21.6029 7.02627 24.2413 8.73593 26.79C10.444 29.3363 12.7626 31.6842 15.1556 33.701C17.541 35.7115 19.9461 37.3477 21.7773 38.4806C22.6915 39.0462 23.454 39.481 23.9887 39.7697C23.9925 39.7717 23.9962 39.7737 24 39.7758C24.0038 39.7737 24.0075 39.7717 24.0113 39.7697C24.546 39.481 25.3085 39.0462 26.2227 38.4806C28.0539 37.3477 30.459 35.7115 32.8444 33.701C35.2374 31.6842 37.556 29.3363 39.2641 26.79C40.9737 24.2413 42 21.6029 42 18.96C42 13.4678 37.8488 8 32.2222 8C28.9397 8 26.807 9.63647 25.5225 11.1444C25.1425 11.5905 24.586 11.8475 24 11.8475C23.414 11.8475 22.8575 11.5905 22.4775 11.1444C21.193 9.63647 19.0603 8 15.7778 8ZM2 18.96C2 11.9422 7.31548 4 15.7778 4C19.4319 4 22.1322 5.42101 24 7.02165C25.8678 5.42101 28.5681 4 32.2222 4C40.6845 4 46 11.9422 46 18.96C46 22.6509 44.5707 26.0594 42.5859 29.0182C40.5996 31.9795 37.9848 34.5998 35.4222 36.7596C32.8521 38.9257 30.2795 40.6744 28.3273 41.8822C27.3498 42.4869 26.5193 42.9614 25.9116 43.2895C25.6093 43.4526 25.3525 43.5849 25.1543 43.6808C25.0578 43.7274 24.9574 43.7742 24.8638 43.8135C24.8196 43.8321 24.7518 43.8597 24.6737 43.8862C24.6354 43.8993 24.5709 43.9201 24.4912 43.9398L24.4898 43.9402C24.4391 43.9527 24.2475 44 24 44C23.7525 44 23.5609 43.9527 23.5102 43.9402L23.5088 43.9398C23.4291 43.9201 23.3646 43.8993 23.3263 43.8862C23.2482 43.8597 23.1804 43.8321 23.1362 43.8135C23.0426 43.7742 22.9422 43.7274 22.8457 43.6808C22.6475 43.5849 22.3907 43.4526 22.0884 43.2895C21.4807 42.9614 20.6502 42.4869 19.6727 41.8822C17.7205 40.6744 15.1479 38.9257 12.5778 36.7596C10.0152 34.5998 7.40045 31.9795 5.41407 29.0182C3.42928 26.0594 2 22.6509 2 18.96Z" fill="#EE6D21"/>
                      </svg>
                    </div>
                    <p>support</p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-6">
                  <div className='icon d-flex align-items-center'>
                    <div className='svg'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.7778 8C10.1512 8 6 13.4678 6 18.96C6 21.6029 7.02627 24.2413 8.73593 26.79C10.444 29.3363 12.7626 31.6842 15.1556 33.701C17.541 35.7115 19.9461 37.3477 21.7773 38.4806C22.6915 39.0462 23.454 39.481 23.9887 39.7697C23.9925 39.7717 23.9962 39.7737 24 39.7758C24.0038 39.7737 24.0075 39.7717 24.0113 39.7697C24.546 39.481 25.3085 39.0462 26.2227 38.4806C28.0539 37.3477 30.459 35.7115 32.8444 33.701C35.2374 31.6842 37.556 29.3363 39.2641 26.79C40.9737 24.2413 42 21.6029 42 18.96C42 13.4678 37.8488 8 32.2222 8C28.9397 8 26.807 9.63647 25.5225 11.1444C25.1425 11.5905 24.586 11.8475 24 11.8475C23.414 11.8475 22.8575 11.5905 22.4775 11.1444C21.193 9.63647 19.0603 8 15.7778 8ZM2 18.96C2 11.9422 7.31548 4 15.7778 4C19.4319 4 22.1322 5.42101 24 7.02165C25.8678 5.42101 28.5681 4 32.2222 4C40.6845 4 46 11.9422 46 18.96C46 22.6509 44.5707 26.0594 42.5859 29.0182C40.5996 31.9795 37.9848 34.5998 35.4222 36.7596C32.8521 38.9257 30.2795 40.6744 28.3273 41.8822C27.3498 42.4869 26.5193 42.9614 25.9116 43.2895C25.6093 43.4526 25.3525 43.5849 25.1543 43.6808C25.0578 43.7274 24.9574 43.7742 24.8638 43.8135C24.8196 43.8321 24.7518 43.8597 24.6737 43.8862C24.6354 43.8993 24.5709 43.9201 24.4912 43.9398L24.4898 43.9402C24.4391 43.9527 24.2475 44 24 44C23.7525 44 23.5609 43.9527 23.5102 43.9402L23.5088 43.9398C23.4291 43.9201 23.3646 43.8993 23.3263 43.8862C23.2482 43.8597 23.1804 43.8321 23.1362 43.8135C23.0426 43.7742 22.9422 43.7274 22.8457 43.6808C22.6475 43.5849 22.3907 43.4526 22.0884 43.2895C21.4807 42.9614 20.6502 42.4869 19.6727 41.8822C17.7205 40.6744 15.1479 38.9257 12.5778 36.7596C10.0152 34.5998 7.40045 31.9795 5.41407 29.0182C3.42928 26.0594 2 22.6509 2 18.96Z" fill="#EE6D21"/>
                      </svg>
                    </div>
                    <p>Care</p>
                  </div>
                </div>
                <div className="col-lg-6">
                <div className='icon d-flex align-items-center'>
                    <div className='svg'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.7778 8C10.1512 8 6 13.4678 6 18.96C6 21.6029 7.02627 24.2413 8.73593 26.79C10.444 29.3363 12.7626 31.6842 15.1556 33.701C17.541 35.7115 19.9461 37.3477 21.7773 38.4806C22.6915 39.0462 23.454 39.481 23.9887 39.7697C23.9925 39.7717 23.9962 39.7737 24 39.7758C24.0038 39.7737 24.0075 39.7717 24.0113 39.7697C24.546 39.481 25.3085 39.0462 26.2227 38.4806C28.0539 37.3477 30.459 35.7115 32.8444 33.701C35.2374 31.6842 37.556 29.3363 39.2641 26.79C40.9737 24.2413 42 21.6029 42 18.96C42 13.4678 37.8488 8 32.2222 8C28.9397 8 26.807 9.63647 25.5225 11.1444C25.1425 11.5905 24.586 11.8475 24 11.8475C23.414 11.8475 22.8575 11.5905 22.4775 11.1444C21.193 9.63647 19.0603 8 15.7778 8ZM2 18.96C2 11.9422 7.31548 4 15.7778 4C19.4319 4 22.1322 5.42101 24 7.02165C25.8678 5.42101 28.5681 4 32.2222 4C40.6845 4 46 11.9422 46 18.96C46 22.6509 44.5707 26.0594 42.5859 29.0182C40.5996 31.9795 37.9848 34.5998 35.4222 36.7596C32.8521 38.9257 30.2795 40.6744 28.3273 41.8822C27.3498 42.4869 26.5193 42.9614 25.9116 43.2895C25.6093 43.4526 25.3525 43.5849 25.1543 43.6808C25.0578 43.7274 24.9574 43.7742 24.8638 43.8135C24.8196 43.8321 24.7518 43.8597 24.6737 43.8862C24.6354 43.8993 24.5709 43.9201 24.4912 43.9398L24.4898 43.9402C24.4391 43.9527 24.2475 44 24 44C23.7525 44 23.5609 43.9527 23.5102 43.9402L23.5088 43.9398C23.4291 43.9201 23.3646 43.8993 23.3263 43.8862C23.2482 43.8597 23.1804 43.8321 23.1362 43.8135C23.0426 43.7742 22.9422 43.7274 22.8457 43.6808C22.6475 43.5849 22.3907 43.4526 22.0884 43.2895C21.4807 42.9614 20.6502 42.4869 19.6727 41.8822C17.7205 40.6744 15.1479 38.9257 12.5778 36.7596C10.0152 34.5998 7.40045 31.9795 5.41407 29.0182C3.42928 26.0594 2 22.6509 2 18.96Z" fill="#EE6D21"/>
                      </svg>
                    </div>
                    <p>support</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
          
          <div className="row photos">
          

            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div className="photo photo-1" style={image1Style}>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="photo photo-2" style={image2Style}>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="photo photo-3" style={image3Style}>
                  </div>
                </div>
                <div className="col-lg-2">
                    
                  <div className="photo photo-4" style={image4Style}>
                  </div>
                  <div className="photo photo-5" style={image5Style}>
                  </div>

                </div>
                <div className="col-lg-4">
                  <div className="photo photo-6" style={image6Style}>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
          
          <div className="row projects">
            
          <div className="col-lg-12">
              <div className="row">

            <div className="col-lg-4">
              <h2>Current projects</h2>
            
            </div>
            <div className="col-lg-4">
            <Project/>  
            </div>
            <div className="col-lg-4">
            <Project/>  
            </div>
          </div>
            </div>
          </div>
          
          {/* <div className="row reports">
          <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-3">
              
              <h1>Reports</h1>
            </div>
            <div className="col-lg-3">
            </div>
            <div className="col-lg-3">
            
            </div>
            <div className="col-lg-3">
            
            </div>
          </div>
            </div>
          </div>
          
          <div className="row index">
            <div className="col-lg-3">
            
            </div>
            <div className="col-lg-6">
              <h1>The Smith Family</h1>
              <p>khjh jhg jhgjhgjh jhg jh jhg jhg jhg ghjgjhbjhb </p>
            </div>
            <div className="col-lg-3">
            
            </div>
          </div> */}
          
          <div className="row contact">
            <div className="col-lg-4">
              <h2>Contact</h2>
            
            </div>
            <div className="col-lg-4">
              <img src=""/>
              <p className='contact-title'>Fundraising</p>
              <p className='name'>Carey Milligan</p>
              <p className='email'>Fundraising</p>
              
            </div>
            <div className="col-lg-4">
            
            </div>
          </div>

        </div>
      </div>
    </AppWrapper>
  )
}

const getUrl = (url) => Api.get({ url })

export default NfpProfilePage



