import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import FileUpload from '../file-upload'



import './style.scss'

import Accordion from '../accordian'



const FinantialsForm = ({  children, className, redirect, year, callback}) => {
  const user = Session.getUserData()

  // let welcomeClass = "welcome-info";
    if(!!year){
      className = "financials-form "+year;
    }else{
      className = "financials-form";
    }


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const [totalrevenue, setTotalRevenue] = useState('100')
  const [totalprogramexpenses, setTotalProgramExpenses] = useState('100')
  const [totalfundraisingexpenses, setTotalFundraisingExpenses] = useState('100')
  const [totalassets, setTotalAssets] = useState('100')
  const [totalliabilities, setTotalLiabilities] = useState('100')
  const [totaldonationsrecived, setTotalDonationsRecived] = useState('100')
  const [totaladministrativeexpenses, setTotalAdministrativeExpenses] = useState('100')
  const [currentassets, setCurrentAssets] = useState('100')
  const [currentliabilities, setCurrentLiabilities] = useState('100')

  let charityId = "1"
  let editedBy = "default";

  if(!!user){
    editedBy = user._id;
  }



  if(!year){
    year = "2022";
  }


  const fetchData = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const response = await fetch('/api/get-financials/'+year);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();


      // setData(result);
      console.log(result);



      setTotalRevenue(result.financial.totalrevenue)
      setTotalProgramExpenses(result.financial.totalprogramexpenses)
      setTotalFundraisingExpenses(result.financial.totalfundraisingexpenses)
      setTotalAssets(result.financial.totalassets)
      setTotalLiabilities(result.financial.totalliabilities)
      setTotalDonationsRecived(result.financial.totaldonationsrecived)
      setTotalAdministrativeExpenses(result.financial.totaladministrativeexpenses)
      setCurrentAssets(result.financial.currentassets)
      setCurrentLiabilities(result.financial.currentliabilities)


    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    console.log("***");
    console.log(user);
    console.log(editedBy);

    Api.post({
      url: '/api/save-financials',
      data: { year, totalrevenue, totalprogramexpenses, totalfundraisingexpenses, totalassets, totalliabilities, totaldonationsrecived, totaladministrativeexpenses, currentassets, currentliabilities, charityId, editedBy },
    }).then((res) => {
      if (!res.success || res.error ) {
        return setError(res.error || 'Invalid details')
      }
      callback(res);
      // history.push(redirect)
    })
  }




    return (
      <div className={className}>
        <form onSubmit={handleSubmit} id="theForm">
        <div className="row">
          <h5 className="year">{year} Totals</h5>

            <Input type="text" value={totalrevenue} onChange={setTotalRevenue} label={'Total revenue'} />
            <Input type="text" value={totalprogramexpenses} onChange={setTotalProgramExpenses} label={'Total program expenses'} />
            <Input type="text" value={totalfundraisingexpenses} onChange={setTotalFundraisingExpenses} label={'Total fundraising expenses'} />
            <Input type="text" value={totalassets} onChange={setTotalAssets} label={'Total assets'} />
            <Input type="text" value={totalliabilities} onChange={setTotalLiabilities} label={'Total liabilities'} />

            <Input type="text" value={totaldonationsrecived} onChange={setTotalDonationsRecived} label={'Total donations recived'} />
            <Input type="text" value={totaladministrativeexpenses} onChange={setTotalAdministrativeExpenses} label={'Total administrative expenses'} />
            <Input type="text" value={currentassets} onChange={setCurrentAssets} label={'Current assets'} />
            <Input type="text" value={currentliabilities} onChange={setCurrentLiabilities} label={'Current liabilities'} />

            <Input type="text" value={currentliabilities} onChange={setCurrentLiabilities} label={'Surplus or Deficit this year'} />


            <div className='checkbox'>
              <input type="checkbox" value="Surpus" onChange={setCurrentLiabilities} label={'Surpus'} />
              <label>Surpus</label>
            </div>
            <div className='checkbox'>
              <input type="checkbox" value="Deficit" onChange={setCurrentLiabilities} label={'Deficit'} />
              <label>Deficit</label>
            </div>


            <div className='upload'>
              <label>Finantial Statement</label>
              <FileUpload
                uploadUrl="/upload"
                // inputStyle={inputStyle}
                // previewStyle={previewStyle}
                // buttonStyle={buttonStyle}
              />
            </div>


            {/* <FileUpload /> */}

<button className="submit" onClick={handleSubmit} > submit </button>
        </div>
        </form>
      </div>
    )

}

export default FinantialsForm
