import React, { useState } from 'react';
import './style.scss'

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderedItems = items.map((item, index) => {
    const active = index === activeIndex ? 'active' : 'collapsed';

// accordion-button collapsed
// 

    return (
      <div key={index} className={`item ${active}`}>
        <div className="title" onClick={() => onTitleClick(index)}>
          <div className={`dropdown accordion-button ${active}`}>
            {item.question}
          </div>
        </div>
        <div className={`content ${active}`}>
          <p>{item.answer}</p>
        </div>
      </div>
    );
  });

  return <div className="ui styled accordion">{renderedItems}</div>;
};

export default Accordion;
