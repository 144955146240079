import * as serviceWorker from './serviceWorker'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './styles/bootstrap-icons.scss';
import './styles/template.scss'
import './styles/index.scss'

import './routes'

var globalThis = require('globalthis/polyfill')();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
