import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import FileUpload from '../file-upload'
import ImageUpload from '../image-upload'



import './style.scss'

import Accordion from '../accordian'



const ProjectFormFaqs = ({  children, className, redirect, year, callback, name, location, projectId}) => {
  const user = Session.getUserData()
  const charity = Session.getCharityData()

  console.log(charity);


  // let welcomeClass = "welcome-info";
    if(!!charity){
      className = "profile-form-intro "+projectId;
    }else{
      className = "profile-form-intro";
    }


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const [faq1q, setfaq1q] = useState('1')
  const [faq1d, setfaq1d] = useState('2')
  const [faq2q, setfaq2q] = useState('3')
  const [faq2d, setfaq2d] = useState('4')
  const [faq3q, setfaq3q] = useState('5')
  const [faq3d, setfaq3d] = useState('6')
  const [faq4q, setfaq4q] = useState('7')
  const [faq4d, setfaq4d] = useState('8')



  // let charityId = "1"
  let editedBy = "default";

  if(!!user){
    editedBy = user._id;
  }


  


  // if(!charity){
  //   charity = "Charity 1";
  // }


  const fetchData = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const response = await fetch('/api/project-info/'+projectId);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();


      // setData(result);
      console.log(result);
      // setcoverImage(result.projectInfo.coverImage)
      // setprojectTitle(result.projectInfo.projectTitle)
      // setdescription(result.projectInfo.description)
      setfaq1q(result.projectFaq.faq1q)
      setfaq1d(result.projectFaq.faq1d)
      setfaq2q(result.projectFaq.faq2q)
      setfaq2d(result.projectFaq.faq2d)
      setfaq3q(result.projectFaq.faq3q)
      setfaq3d(result.projectFaq.faq3d)
      setfaq4q(result.projectFaq.faq4q)
      setfaq4d(result.projectFaq.faq4d)

      // setTotalRevenue(result.financial.totalrevenue)
      // setTotalProgramExpenses(result.financial.totalprogramexpenses)
      // setTotalFundraisingExpenses(result.financial.totalfundraisingexpenses)
      // setTotalAssets(result.financial.totalassets)
      // setTotalLiabilities(result.financial.totalliabilities)
      // setTotalDonationsRecived(result.financial.totaldonationsrecived)
      // setTotalAdministrativeExpenses(result.financial.totaladministrativeexpenses)
      // setCurrentAssets(result.financial.currentassets)
      // setCurrentLiabilities(result.financial.currentliabilities)


    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };

  // useEffect(() => {
  //   if(!!projectId){
  //     fetchData();
  //   }
    
  // }, [projectId]);

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    console.log("***");
    console.log(user);
    console.log(editedBy);


    let registeredCharityNumber = charity.registeredCharityNumber;


    // callback();
    Api.post({
      url: '/api/project-faq',
      data: { projectId, faq1q, faq1d, faq2q, faq2d, faq3q, faq3d, faq4q, faq4d },
    }).then((res) => {
      if (!res.success || res.error ) {
        return setError(res.error || 'Invalid details')
      }
      callback(res,redirect);
      // history.push(redirect)
    })
  }

  let inputStyle = {};
  let previewStyle = {};
  let buttonStyle = {};


    return (
      <div className={className}>
        <form onSubmit={handleSubmit} id="theForm">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <Input type="text" value={faq1q} onChange={setfaq1q} label={'Question 1 Title'} />
            <Input type="textarea" value={faq1d} onChange={setfaq1d} label={'Question 1 Description'} rows="5" />
          </div>
          <div className="col-lg-12">
            <Input type="text" value={faq2q} onChange={setfaq2q} label={'Question 2 Title'} />
            <Input type="textarea" value={faq2d} onChange={setfaq2d} label={'Question 2 Description'} rows="5" />
          </div>
          <div className="col-lg-12">
            <Input type="text" value={faq3q} onChange={setfaq3q} label={'Question 3 Title'} />
            <Input type="textarea" value={faq3d} onChange={setfaq3d} label={'Question 3 Description'} rows="5" />
          </div>
          <div className="col-lg-12">
            <Input type="text" value={faq4q} onChange={setfaq4q} label={'Question 4 Title'} />
            <Input type="textarea" value={faq4d} onChange={setfaq4d} label={'Question 4 Description'} rows="5" />
          </div>

          <button className="submit" onClick={handleSubmit} > submit </button>
        </div>
        </form>
      </div>
    )

}

export default ProjectFormFaqs
