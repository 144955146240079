import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import FileUpload from '../file-upload'
import ImageUpload from '../image-upload'



import './style.scss'

import Accordion from '../accordian'



const ProfileFormStory = ({  children, className, redirect, year, callback, name, location}) => {
  const user = Session.getUserData()
  const charity = Session.getCharityData()

  console.log(charity);
  // let welcomeClass = "welcome-info";
    if(!!charity){
      className = "profile-form-intro "+charity.registeredCharityNumber;
    }else{
      className = "profile-form-intro";
    }


  const [error, setError] = useState(null)


  const [mission, setmission] = useState('')
  const [companyValue1, setcompanyValue1] = useState('')
  const [companyValue2, setcompanyValue2] = useState('')
  const [companyValue3, setcompanyValue3] = useState('')
  const [companyValue4, setcompanyValue4] = useState('')
  const [companyValue5, setcompanyValue5] = useState('')
  const [companyValue6, setcompanyValue6] = useState('')
  const [image1, setimage1] = useState('')
  const [image2, setimage2] = useState('')
  const [image3, setimage3] = useState('')
  const [image4, setimage4] = useState('')
  const [image5, setimage5] = useState('')
  const [image6, setimage6] = useState('')

  let charityId = "1"
  let editedBy = "default";

  if(!!user){
    editedBy = user._id;
  }


  const fetchData = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const response = await fetch('/api/nfp-account-story/'+charity.registeredCharityNumber);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();


      // setData(result);
      console.log(result);
      setmission(result.charityStoryInfo.mission)
      setcompanyValue1(result.charityStoryInfo.companyValue1)
      setcompanyValue2(result.charityStoryInfo.companyValue2)
      setcompanyValue3(result.charityStoryInfo.companyValue3)
      setcompanyValue4(result.charityStoryInfo.companyValue4)
      setcompanyValue5(result.charityStoryInfo.companyValue5)
      setcompanyValue6(result.charityStoryInfo.companyValue6)
      setimage1(result.charityStoryInfo.image1)
      setimage2(result.charityStoryInfo.image2)
      setimage3(result.charityStoryInfo.image3)
      setimage4(result.charityStoryInfo.image4)
      setimage5(result.charityStoryInfo.image5)
      setimage6(result.charityStoryInfo.image6)

      // setTotalRevenue(result.financial.totalrevenue)
      // setTotalProgramExpenses(result.financial.totalprogramexpenses)
      // setTotalFundraisingExpenses(result.financial.totalfundraisingexpenses)
      // setTotalAssets(result.financial.totalassets)
      // setTotalLiabilities(result.financial.totalliabilities)
      // setTotalDonationsRecived(result.financial.totaldonationsrecived)
      // setTotalAdministrativeExpenses(result.financial.totaladministrativeexpenses)
      // setCurrentAssets(result.financial.currentassets)
      // setCurrentLiabilities(result.financial.currentliabilities)


    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    console.log("***");
    console.log(user);
    console.log(editedBy);

    let nfpName= "Charity 1";

    // console.log(coverImg);

    // console.log(coverLogo);
    // console.log(about);

    let registeredCharityNumber = charity.registeredCharityNumber;

    // callback();
    Api.post({
      url: '/api/nfp-account-story',
      data: { registeredCharityNumber, mission,companyValue1,companyValue2,companyValue3,companyValue4,companyValue5,companyValue6,image1,image2,image3,image4,image5,image6 },
    }).then((res) => {
      if (!res.success || res.error ) {
        return setError(res.error || 'Invalid details')
      }
      callback(res,redirect);
      // history.push(redirect)
    })
  }

  let inputStyle = {};
  let previewStyle = {};
  let buttonStyle = {};


    return (
      <div className={className}>
        <form onSubmit={handleSubmit} id="theForm">
        <div className="row">




        <Input type="textarea" value={mission} onChange={setmission} label={'Mission'} rows="10" />

        <h3>Company values</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>


        
        <div className="row">
          <div className="col-lg-6">
            <Input type="text" value={companyValue1} onChange={setcompanyValue1} label={'Name 1'} />
            <Input type="text" value={companyValue2} onChange={setcompanyValue2} label={'Name 1'} />
            <Input type="text" value={companyValue3} onChange={setcompanyValue3} label={'Name 1'} />
          </div>
          <div className="col-lg-6">
            <Input type="text" value={companyValue4} onChange={setcompanyValue4} label={'Name 1'} />
            <Input type="text" value={companyValue5} onChange={setcompanyValue5} label={'Name 1'} />
            <Input type="text" value={companyValue6} onChange={setcompanyValue6} label={'Name 1'} />
          </div>
        </div>



        <h3>Images</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>


        <div className="row">        
          <div className="offset-1 col-lg-10">
            <div className="row">
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimage1}
                  value={image1}
                />
              </div>
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimage2}
                  value={image2}
                />
              </div>
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimage3}
                  value={image3}
                />
              </div>

            </div>
            <div className="row">
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimage4}
                  value={image4}
                />
              </div>
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimage5}
                  value={image5}
                />
              </div>
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimage6}
                  value={image6}
                />
              </div>

            </div>
          </div>
        </div>

          <button className="submit" onClick={handleSubmit} > submit </button>
        </div>
        </form>
      </div>
    )

}

export default ProfileFormStory
