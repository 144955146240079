import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss'
import { history, getPrevLocation } from '../history'
import Button from "../button"
// import { Container, Row, Col, Button } from 'react-bootstrap';

const QuestionnaireOverviewCard = ({ title, progress, buttonLink }) => {
  // const history = useHistory();

  const handleButtonClick = () => {
    // history.push(buttonLink);
    console.log(buttonLink);
    history.push(buttonLink);
  };

  let status = "Not Started";
  let className = "grey";
  let nextStep = "Start"
  let nextStyle = "primary green"

  if(progress > 0){
    status = "In Progress";
    className = "";
    nextStep = "Resume"
    nextStyle = "primary"
  }
  if(progress == 100){
    status = "Complete";
    className = "green";
    nextStep = "View"
    nextStyle = "secondary green"
  }
  



  return (
    <div className="questionnaire-overview-card card p-3" style={{ borderRadius: '10px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)' }}>
      <div className="row card-content">
      
      <div className="col-lg-6 card-head">

      <div className="circle "></div>

      </div>
      <div className="offset-2 col-lg-4 card-head">

        
        <div className=" align-items-center" >

        {/* <div className="row"> */}
          <Button className={`primary sml ${className}`}>{status}</Button>
          {/* </div> */}
          {/* <div className="row"> */}
          <Button className={`secondary sml ${className}`}>{progress}%</Button>
          {/* </div> */}
          
          {/* <div className="progress-circle" style={{ position: 'relative', width: '40px', height: '40px' }}>
            <svg viewBox="0 0 36 36" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
              <path
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke="#e6e6e6"
                strokeWidth="2.8"
              />
              <path
                d="M18 2.0845
                a 15.9155 15.9155 0 1 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke="#00aaff"
                strokeWidth="2.8"
                strokeDasharray={`${progress}, 100`}
                className='percentage-stroke'
              />
            </svg>
            <div className="progress-text" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              {progress}%
            </div>
          </div> */}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center card-body">
        <div className="">
          <h5 className="card-title mb-0">{title}</h5>
          <p className="card-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className="card-button mt-2">
            <Button type="button" className={`sml ${nextStyle}`}  onClick={handleButtonClick}>{nextStep}</Button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default QuestionnaireOverviewCard;
