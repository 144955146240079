import React from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header-nomenu'
import Footer from '../components/footer'
import Menu from '../components/menu'

import '../styles/landing-v1-wrapper.scss'

function App({ children, className }) {

  let mainStyle = {
    marginLeft:0,
    paddingRight:"30px"
  };

  return (
    <div className={className}>
      <span className="divide-line" ></span>
      <div className="app">
        <Header />
        <Menu className="d-lg-none"/>
        <main className="main" id="main" style={mainStyle}>{children}</main>
      </div>
        <Footer />
    </div>
  )
}

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default App
