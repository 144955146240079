import React from 'react'
import { Link } from 'react-router-dom'
import Api from 'easy-fetch-api'
import AppWrapper from '../containers/app-wrapper-cream'
import Slider from '../components/slider'
import WelcomeInfo from '../components/welcome-info'
import ProfileForm from '../components/profile-form'
import Button from '../components/button'


import SliderPanel from '../components/slider-panel'
import QuestionnaireOverviewCard from '../components/questionnaire-overview'

import '../styles/pages/questionaire.scss'




const QuestionnairePage = () => {


  const fakeImg = {
      color: "white",
      backgroundColor: "grey",
      padding: "10px",
      fontFamily: "Arial",
      // borderRadius: "10px"
      height : "600px",
      width : "600px"
    };

    const customButton={
      title:"Custom Button"
    };

    const inputStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px',
      border: '2px solid #ccc',
      borderRadius: '5px',
    };

    const previewStyle = {
      textAlign: 'center',
      margin: '20px auto',
    };

    const buttonStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px 20px',
      backgroundColor: '#28a745',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    };

    const circle={
      height: "100px",
      width: "100px",
      borderRadius: "50%",
      background: "#aaa"
    }

    const push={
      marginTop:"34%"
    }


  return (
    <AppWrapper >
      <div className="questionaire-page container">
        <div className="row">

              <div className="col-lg-4 align-middle">
                <div className="align-middle main-content" style={push}>
                  <h1>Get your Charity Index&trade; Rating</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.Lorem ipsum dolor sit amet, consectetur.
                    <br /><br />Complete the following sections to get your charity rating on the platform.
                    </p>

                  <Button className="secondary green">Read about our rating process</Button>
              </div>
              </div>
              <div className="offset-1 col-lg-7 quiz-cards">
              <div className="row">
              <div className="col-lg-6">
                <QuestionnaireOverviewCard title="1. Finantial Ratios" progress={100} buttonLink="/questionnaire-finantials"/>
                </div>
                <div className="col-lg-6">
                <QuestionnaireOverviewCard title="Transparency" progress={78} buttonLink="/questionnaire-transparency"/>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-6">
                <QuestionnaireOverviewCard title="Employment Practises" progress={0} buttonLink="/questionnaire-employment"/>
                </div>
                <div className="col-lg-6">
                <QuestionnaireOverviewCard title="Adaptability & Learning" progress={0} buttonLink="/questionnaire-adaptability"/>
                </div>
                </div>
              </div>
        </div>
      </div>
    </AppWrapper>
  )
}

const getUrl = (url) => Api.get({ url })

export default QuestionnairePage
