import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import FileUpload from '../file-upload'
import ImageUpload from '../image-upload'



import './style.scss'

import Accordion from '../accordian'



const ProjectFormInfo = ({  children, className, redirect, year, callback, name, location, projectId}) => {
  const user = Session.getUserData()
  const charity = Session.getCharityData()

  console.log(charity);


  // let welcomeClass = "welcome-info";
    if(!!charity){
      className = "profile-form-intro "+charity.registeredCharityNumber;
    }else{
      className = "profile-form-intro";
    }


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)


  const [coverImage, setcoverImage] = useState('')
  const [projectTitle, setprojectTitle] = useState('')
  const [description, setdescription] = useState('')
  const [image1, setimage1] = useState('')
  const [image2, setimage2] = useState('')
  const [image3, setimage3] = useState('')
  const [image4, setimage4] = useState('')
  const [image5, setimage5] = useState('')
  const [image6, setimage6] = useState('')



  // let charityId = "1"
  let editedBy = "default";

  if(!!user){
    editedBy = user._id;
  }


  


  // if(!charity){
  //   charity = "Charity 1";
  // }


  const fetchData = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const response = await fetch('/api/project-info/'+projectId);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();


      // setData(result);
      console.log(result);
      setcoverImage(result.projectInfo.coverImage)
      setprojectTitle(result.projectInfo.projectTitle)
      setdescription(result.projectInfo.description)
      setimage1(result.projectInfo.image1)
      setimage2(result.projectInfo.image2)  
      setimage3(result.projectInfo.image3)
      setimage4(result.projectInfo.image4)
      setimage5(result.projectInfo.image5)
      setimage6(result.projectInfo.image6)

      // setTotalRevenue(result.financial.totalrevenue)
      // setTotalProgramExpenses(result.financial.totalprogramexpenses)
      // setTotalFundraisingExpenses(result.financial.totalfundraisingexpenses)
      // setTotalAssets(result.financial.totalassets)
      // setTotalLiabilities(result.financial.totalliabilities)
      // setTotalDonationsRecived(result.financial.totaldonationsrecived)
      // setTotalAdministrativeExpenses(result.financial.totaladministrativeexpenses)
      // setCurrentAssets(result.financial.currentassets)
      // setCurrentLiabilities(result.financial.currentliabilities)


    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    console.log("***");
    console.log(user);
    console.log(editedBy);


    let registeredCharityNumber = charity.registeredCharityNumber;


    // callback();
    Api.post({
      url: '/api/project-info',
      data: { registeredCharityNumber, projectId, coverImage, projectTitle, description, image1, image2, image3, image4, image5, image6 },
    }).then((res) => {
      if (!res.success || res.error ) {
        return setError(res.error || 'Invalid details')
      }
      callback(res,redirect);
      // history.push(redirect)
    })
  }

  let inputStyle = {};
  let previewStyle = {};
  let buttonStyle = {};


    return (
      <div className={className}>
        <form onSubmit={handleSubmit} id="theForm">
        <div className="row justify-content-center">
        <div className="wrapper">
        <div className="label-error-wrapper">
          <label className="cover-img-label">Add a cover image</label>
        </div>
        <ImageUpload
                uploadUrl="/upload"
                inputStyle={inputStyle}
                previewStyle={previewStyle}
                buttonStyle={buttonStyle}
                className="cover-img"
                onChange={setcoverImage}
                value={coverImage}
              />
</div>

<Input type="text" value={projectTitle} onChange={setprojectTitle} label={'Project Title'} />

<Input type="textarea" value={description} onChange={setdescription} label={'Description'} rows="10" />

<div class="input-wrapper"><div class="label-error-wrapper"><label class="input-name-label">Description</label></div>
<p>hgfhgfhgf hgf hgf hgf gh</p>
<div class="wrapper__prefix row justify-content-center">
<div className="col-lg-10 images">
  <div className="row">
    <div className="col-lg-4">
      <ImageUpload
        uploadUrl="/upload"
        inputStyle={inputStyle}
        previewStyle={previewStyle}
        buttonStyle={buttonStyle}
        className="cover-img"
        onChange={setimage1}
        value={image1}
      />
    </div>
    <div className="col-lg-4">
      <ImageUpload
        uploadUrl="/upload"
        inputStyle={inputStyle}
        previewStyle={previewStyle}
        buttonStyle={buttonStyle}
        className="cover-img"
        onChange={setimage2}
        value={image2}
      />
    </div>
    <div className="col-lg-4">
      <ImageUpload
        uploadUrl="/upload"
        inputStyle={inputStyle}
        previewStyle={previewStyle}
        buttonStyle={buttonStyle}
        className="cover-img"
        onChange={setimage3}
        value={image3}
      />
    </div>
  </div>
  <div className="row">
    <div className="col-lg-4">
      <ImageUpload
        uploadUrl="/upload"
        inputStyle={inputStyle}
        previewStyle={previewStyle}
        buttonStyle={buttonStyle}
        className="cover-img"
        onChange={setimage4}
        value={image4}
      />
    </div>
    <div className="col-lg-4">
      <ImageUpload
        uploadUrl="/upload"
        inputStyle={inputStyle}
        previewStyle={previewStyle}
        buttonStyle={buttonStyle}
        className="cover-img"
        onChange={setimage5}
        value={image5}
      />
    </div>
    <div className="col-lg-4">
      <ImageUpload
        uploadUrl="/upload"
        inputStyle={inputStyle}
        previewStyle={previewStyle}
        buttonStyle={buttonStyle}
        className="cover-img"
        onChange={setimage6}
        value={image6}
      />
    </div>
  </div>
</div></div></div>

{/* <Input type="textarea" value={about} onChange={setAbout} label={'About the organisation'} rows="10" /> */}



<button className="submit" onClick={handleSubmit} > submit </button>
        </div>
        </form>
      </div>
    )

}

export default ProjectFormInfo
