import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import FileUpload from '../file-upload'
import ImageUpload from '../image-upload'



import './style.scss'

import Accordion from '../accordian'



const ProjectFormSettings = ({  children, className, redirect, year, callback, name, location, projectId}) => {
  const user = Session.getUserData()
  const charity = Session.getCharityData()

  console.log(charity);


  // let welcomeClass = "welcome-info";
    if(!!charity){
      className = "profile-form-intro "+projectId;
    }else{
      className = "profile-form-intro";
    }


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)


  
  const [notificationNewDonor, setnotificationNewDonor] = useState("true")
  const [notificationNewDonation, setnotificationNewDonation] = useState("true")
  const [notificationTargetReached, setnotificationTargetReached] = useState("true")
  const [notificationNewFollow, setnotificationNewFollow] = useState("true")


  // let charityId = "1"
  let editedBy = "default";

  if(!!user){
    editedBy = user._id;
  }


  


  // if(!charity){
  //   charity = "Charity 1";
  // }


  const fetchData = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const response = await fetch('/api/project-info/'+projectId);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();

      // setData(result);
      console.log(result);
      setnotificationNewDonor(result.projectSettings.notificationNewDonor)
      setnotificationNewDonation(result.projectSettings.notificationNewDonation)
      setnotificationTargetReached(result.projectSettings.notificationTargetReached)
      setnotificationNewFollow(result.projectSettings.notificationNewFollow)


    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if(!!projectId){
      // fetchData();
    }
  }, [projectId]);

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    console.log("***");
    console.log(user);
    console.log(editedBy);


    let registeredCharityNumber = charity.registeredCharityNumber;


    // callback();
    Api.post({
      url: '/api/project-settings',
      data: { projectId, notificationNewDonor, notificationNewDonation, notificationTargetReached, notificationNewFollow },
    }).then((res) => {
      if (!res.success || res.error ) {
        return setError(res.error || 'Invalid details')
      }
      callback(res,redirect);
      // history.push(redirect)
    })
  }

  let inputStyle = {};
  let previewStyle = {};
  let buttonStyle = {};


    return (
      <div className={className}>
        <form onSubmit={handleSubmit} id="theForm">
          <div className="row justify-content-center">
            <div className="wrapper">
              <div className="label-error-wrapper">
                <label className="cover-img-label">Notifications</label>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <Input type="checkbox" value={notificationNewDonor} onChange={setnotificationNewDonor} label={'New Donor'} />
              <Input type="checkbox" value={notificationNewDonation} onChange={setnotificationNewDonation} label={'New Donation'} />
              <Input type="checkbox" value={notificationTargetReached} onChange={setnotificationTargetReached} label={'Target Reached'} />
              <Input type="checkbox" value={notificationNewFollow} onChange={setnotificationNewFollow} label={'New Follow'} />
              
            </div>
            <button className="submit" onClick={handleSubmit} > submit </button>
          </div>
        </form>
      </div>
    )

}

export default ProjectFormSettings
