import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import FileUpload from '../file-upload'
import ImageUpload from '../image-upload'



import './style.scss'

import Accordion from '../accordian'



const ProfileFormIndepth = ({  children, className, redirect, year, callback, name, location}) => {
  const user = Session.getUserData()
  const charity = Session.getCharityData()

  console.log(charity);

  // let welcomeClass = "welcome-info";
    if(!!charity){
      className = "profile-form-intro "+charity.registeredCharityNumber;
    }else{
      className = "profile-form-intro";
    }


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const [totalrevenue, setTotalRevenue] = useState('100')
  const [totalprogramexpenses, setTotalProgramExpenses] = useState('100')
  const [totalfundraisingexpenses, setTotalFundraisingExpenses] = useState('100')
  const [totalassets, setTotalAssets] = useState('100')
  const [totalliabilities, setTotalLiabilities] = useState('100')
  const [totaldonationsrecived, setTotalDonationsRecived] = useState('100')
  const [totaladministrativeexpenses, setTotalAdministrativeExpenses] = useState('100')
  const [currentassets, setCurrentAssets] = useState('100')
  const [currentliabilities, setCurrentLiabilities] = useState('100')

  const [coverImg, setCoverImg] = useState('')
  const [otherReports, setotherReports] = useState('')
  const [otherDocuments, setotherDocuments] = useState('')


  let charityId = "1"
  let editedBy = "default";

  if(!!user){
    editedBy = user._id;
  }


  


  const fetchData = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const response = await fetch('/api/nfp-account-indepth/'+charity.registeredCharityNumber);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();


      // setData(result);
      console.log(result);
      setotherReports(result.charityIndepthInfo.otherReports)
      setotherDocuments(result.charityIndepthInfo.otherDocuments)

      // setTotalRevenue(result.financial.totalrevenue)
      // setTotalProgramExpenses(result.financial.totalprogramexpenses)
      // setTotalFundraisingExpenses(result.financial.totalfundraisingexpenses)
      // setTotalAssets(result.financial.totalassets)
      // setTotalLiabilities(result.financial.totalliabilities)
      // setTotalDonationsRecived(result.financial.totaldonationsrecived)
      // setTotalAdministrativeExpenses(result.financial.totaladministrativeexpenses)
      // setCurrentAssets(result.financial.currentassets)
      // setCurrentLiabilities(result.financial.currentliabilities)


    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    console.log("***");
    console.log(user);
    console.log(editedBy);

    let nfpName= "Charity 1";

    let registeredCharityNumber = charity.registeredCharityNumber;


    // callback();
    Api.post({
      url: '/api/nfp-account-indepth',
      data: { registeredCharityNumber, otherReports, otherDocuments },
    }).then((res) => {
      if (!res.success || res.error ) {
        return setError(res.error || 'Invalid details')
      }
      callback(res, redirect);
      // history.push(redirect)
    })
  }

  let inputStyle = {};
  let previewStyle = {};
  let buttonStyle = {};


    return (
      <div className={className}>
        <form onSubmit={handleSubmit} id="theForm">
        <div className="row">

          <div className="wrapper">
            <div className="label-error-wrapper">
              <label className="cover-img-label">Add a cover image</label>
            </div>
            <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setotherReports}
                  value={otherReports}
                />
          </div>

          <div className="wrapper">
            <div className="label-error-wrapper">
              <label className="cover-img-label">Add a cover image</label>
            </div>
            <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setotherDocuments}
                  value={otherDocuments}
                />
          </div>

          <button className="submit" onClick={handleSubmit} > submit </button>
        </div>
        </form>
      </div>
    )

}

export default ProfileFormIndepth
