import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Api from 'easy-fetch-api'
import AppWrapper from '../containers/app-wrapper'
import Slider from '../components/slider'
import WelcomeInfo from '../components/welcome-info'
import ProfileForm from '../components/profile-form'

import SliderPanel from '../components/slider-panel'
import QuestionnaireOverviewCard from '../components/questionnaire-overview'
import FinantialsForm from '../components/finantials-form'
import Button from '../components/button'

// import '../styles/questionnaire-finantials-page.scss'
import '../styles/pages/questionnaire-finantials.scss'

import { useHistory } from "react-router-dom";


const QuestionnaireFinantialsPage = () => {
  const history = useHistory();

  const fakeImg = {
      color: "white",
      backgroundColor: "grey",
      padding: "10px",
      fontFamily: "Arial",
      // borderRadius: "10px"
      height : "600px",
      width : "600px"
    };

    const customButton={
      title:"Custom Button"
    };

    const inputStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px',
      border: '2px solid #ccc',
      borderRadius: '5px',
    };

    const previewStyle = {
      textAlign: 'center',
      margin: '20px auto',
    };

    const buttonStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px 20px',
      backgroundColor: '#28a745',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    };

    const circle={
      height: "100px",
      width: "100px",
      borderRadius: "50%",
      background: "#aaa"
    }

    const push={
      marginTop:"34%"
    }

    let redirect = false;


    const savedraft = (ev) => {
      ev.preventDefault()

      redirect  = true;
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

    const nextPage = (ev) => {
      ev.preventDefault()

      console.log(ev.target.value);
      console.log(this);

      if(!!ev.target.value){
        // redirect="";
        console.log(document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"]));
        document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"])[0].click();


        if(ev.target.value == "2023"){
          // document.getElementsByClassName(["questionaire-header"])[0].style.display = "none!important"
          setIsVisible(false);
        }
      }

    }

    const wip = (ev) => {
      // ev.preventDefault()


      console.log(ev);
      console.log(this);

      if(redirect){
        console.log("redirect to page...")
        history.push("/");
      }

      // document.getElementById("submit").click();


    }

    const [isVisible, setIsVisible] = useState(true);



    let rightStyle = {
      textAlign: "left"
    }

  return (
    <AppWrapper className="questionnaire-finantials">
      <div className='header-title'>

        <div className='upper-title'>Charity Index Rating</div>
        <h1>Finantial Ratios</h1>
      </div>
      <div className="questionnaire-finantials-page">
        <div className="container">

        {/* {isVisible && <div className="d-flex justify-content-between align-items-center questionaire-header">
          <h1>Finantial Ratios</h1>
          <div className="d-flex align-items-center">
            <button className="btn btn-secondary" onClick={savedraft}>save as draft</button>
          </div>
      </div>} */}




          <Slider className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 ">
          <SliderPanel
            className="col-lg-12"
            >
              <div className="align-middle" >
                <div className="">

                  <h1 style={rightStyle}>Intro</h1>
                  <p style={rightStyle}>
                    While our rating system incorporates financial ratios, it is condidered in conjunction with all other relevent information we gather from you. We recognise that genuine change cannot be accurately assessed solely through numerical metrics.
                  </p>
                  {/* <image src="imgs/logo.png" /> */}
                  <img src="/imgs/hero-1.png" alt=""></img>

                  <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage}>Next</Button>
              </div>
                </div>
              </div>

            </SliderPanel>

          <SliderPanel
            className="col-lg-12"
            >


<span>1 of 4</span>
                  <h2>Statements & Expenses 2021</h2>
                  <p>please provide the following amounts for the last 4 years ending 30 June.</p>



              <div className="row">
                <FinantialsForm title="Finantial Ratios" progress={30} buttonLink="/finantial-ratios" year="2021" redirect={redirect} callback={wip}/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="2021">Next</Button>
              </div>
            </SliderPanel>

            <SliderPanel
            className="col-lg-12"
            >


<span>2 of 4</span>
                  <h2>Statements & Expenses 2022</h2>
                  <p>please provide the following amounts for the last 4 years ending 30 June.</p>



              <div className="row">
                <FinantialsForm title="Finantial Ratios" progress={30} buttonLink="/finantial-ratios" year="2022" redirect={redirect} callback={wip}/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="2022">Next</Button>
              </div>
            </SliderPanel>

            <SliderPanel
            className="col-lg-12"
            >


<span>3 of 4</span>
                  <h2>Statements & Expenses 2023</h2>
                  <p>please provide the following amounts for the last 4 years ending 30 June.</p>



              <div className="row">
                <FinantialsForm title="Finantial Ratios" progress={30} buttonLink="/finantial-ratios" year="2023" redirect={redirect} callback={wip}/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="2023">Next</Button>
              </div>
            </SliderPanel>





            <SliderPanel
            className="col-lg-12 section-end"
            >
              <div className="align-middle" >
                <div className="section-end">
                  <img src="/imgs/journey.png" />
                  <h1>Nice! You’re 25% of the way through.</h1>
                  <p>
                    Thanks for submitting all your information. You can expect to hear from us in the next X days for your final rating.
                  </p>
                  <div className='d-flex align-items-center justify-content-between'>
                  <Button className="contact-us secondary green" onClick={function(){history.push("/")}}>Back</Button>
                  <Button className="next-module primary" onClick={function(){history.push("/questionnaire-transparency")}}>Continue to 2. Transparency</Button>
                  </div>
                </div>
              </div>

            </SliderPanel>



          </Slider>
        </div>
      </div>
    </AppWrapper>
  )
}

const getUrl = (url) => Api.get({ url })

export default QuestionnaireFinantialsPage
