import React, { useEffect } from 'react';
import { Children } from 'react';

import { history } from '../history'
import Session from '../session'
import './style.scss'


const Slider = ({ children, className, redirect, hideSlide }) => {
  const user = Session.getUserData()




    let newKids = React.Children.toArray(children);
    let count = 0;

    if(hideSlide){
      newKids.shift();  
      count =1;
    }
// console.log(newKids);
// newKids.shift();
// console.log(newKids);

 //  useEffect(() => {
 //    const script = document.createElement('script');
 //    script.src = "./javascript.js";
 //    script.async = true;
 //    document.body.appendChild(script);
 //    console.log("gotcha");
 // });

  


 useEffect(() => {
    const nextBtnClick = () => {
      const current = document.querySelector('.current');
      const next = document.querySelector('.current').nextElementSibling;

      if (next && next.classList.contains("next")) {
        current.classList.remove('current');
        current.classList.add('anim', 'prev');
        next.classList.remove('next');
        next.classList.add('anim', 'current');
      } else {
        // alert("end");
        setTimeout(function(){
          history.push(redirect)
        }, 200);

      }
    };

    const prevBtnClick = () => {
      const current = document.querySelector('.current');
      const prev = document.querySelector('.current').previousElementSibling;

      if (prev && prev.classList.contains("prev")) {
        current.classList.remove('current');
        current.classList.add('backanim', 'next');
        prev.classList.remove('prev');
        prev.classList.add('backanim', 'current');
      }
    };

    const skipBtnClick = (panel) => {
      console.log("click");
      const current = document.querySelector('.current');
      const next = document.querySelector('.current').nextElementSibling;
      const skip = document.querySelector('.slide-'+panel);

      if (next && next.classList.contains("next")) {
        current.classList.remove('current');
        current.classList.add('anim', 'prev');
        next.classList.remove('next');
        skip.classList.add('anim', 'current');
      } else {
        alert("end");
      }
    };

    const animationEndHandler = () => {
      const containers = document.querySelectorAll(".slider .container");
      containers.forEach(container => {
        container.classList.remove("anim", "backanim");
      });
    };

    const createAccountBtnClick = () => {
      const current = document.querySelector('.current');
      const next = document.querySelector('.current').nextElementSibling;

      console.log(document.querySelector('form').serializeArray());

      const data = {};
      document.querySelectorAll(".form-control").forEach((element) => {
        console.log(element.value);
        console.log(element.id);
        data[element.id] = element.value;
      });

      console.log(data);
      data["type"] = document.querySelector(".type-select.selected").id;

      fetch("api/create-account", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((respdata) => {
          console.log("type", respdata.type);
          if (respdata.type.toLowerCase() === "nfp") {
            window.location.replace("/register-nfp");
          } else if (respdata.type === "business") {
            window.location.replace("/register-nfp");
          } else {
            alert("type not defined");
          }
          document.querySelector(".result").innerHTML = respdata;
        });
    };

    // document.querySelector(".next-btn").addEventListener("click", nextBtnClick);

    const nxtBtns = document.querySelectorAll(".slider .next-btn");
    nxtBtns.forEach(nxtBtn => {
      nxtBtn.addEventListener("click", nextBtnClick);
    });



    if(!!document.querySelector(".prev-btn")){
      // document.querySelector(".prev-btn").addEventListener("click", prevBtnClick);

      const prevBtns = document.querySelectorAll(".slider .prev-btn");
      prevBtns.forEach(prevBtn => {
        prevBtn.addEventListener("click", prevBtnClick);
      });


    }


    if(!!document.querySelector(".skip-btn")){
      // document.querySelector(".prev-btn").addEventListener("click", prevBtnClick);

      const skipBtns = document.querySelectorAll(".slider .skip-btn");
      skipBtns.forEach(skipBtn => {
        console.log(skipBtn);
        skipBtn.addEventListener("click", function(){skipBtnClick(skipBtn.value)});
      });
    }


    const containers = document.querySelectorAll(".slider .container");

    containers.forEach(container => {
        container.addEventListener("webkitAnimationEnd", animationEndHandler);
        container.addEventListener("oanimationend", animationEndHandler);
        container.addEventListener("msAnimationEnd", animationEndHandler);
        container.addEventListener("animationend", animationEndHandler);
    });



    if(!!document.querySelector(".create-account-btn")){
      // document.querySelector(".create-account-btn").addEventListener("click", createAccountBtnClick);

      const createAccBtns = document.querySelectorAll(".slider .create-account-btn");
      createAccBtns.forEach(createAccBtn => {
        createAccBtn.addEventListener("click", createAccountBtnClick);
      });
    }

    return () => {
      const nxtBtns = document.querySelectorAll(".slider .next-btn");
      nxtBtns.forEach(nxtBtn => {
        nxtBtn.addEventListener("click", nextBtnClick);
      });


    if(!!document.querySelector(".prev-btn")){
      // document.querySelector(".prev-btn").addEventListener("click", prevBtnClick);

      const prevBtns = document.querySelectorAll(".slider .prev-btn");
      prevBtns.forEach(prevBtn => {
        prevBtn.addEventListener("click", prevBtnClick);
      });


    }

    if(!!document.querySelector(".skip-btn")){
      // document.querySelector(".prev-btn").addEventListener("click", prevBtnClick);

      const skipBtns = document.querySelectorAll(".slider .skip-btn");
      skipBtns.forEach(skipBtn => {
        console.log(skipBtn);
        skipBtn.addEventListener("click", function(){skipBtnClick(skipBtn.value)});
      });
    }

      const containers = document.querySelectorAll(".slider .container");

      containers.forEach(container => {
          container.addEventListener("webkitAnimationEnd", animationEndHandler);
          container.addEventListener("oanimationend", animationEndHandler);
          container.addEventListener("msAnimationEnd", animationEndHandler);
          container.addEventListener("animationend", animationEndHandler);
      });



      if(!!document.querySelector(".create-account-btn")){
      // document.querySelector(".create-account-btn").addEventListener("click", createAccountBtnClick);

      const createAccBtns = document.querySelectorAll(".slider .create-account-btn");
      createAccBtns.forEach(createAccBtn => {
        createAccBtn.addEventListener("click", createAccountBtnClick);
      });
    }
    };
  }, []);



  // return(
  //   <div className="slider">
  //     <div className="slider-item">1</div>
  //     <div className="slider-item">2</div>
  //     <div className="slider-item">3</div>
  //     <div className="slider-item">4</div>
  //     <div className="slider-item">5</div>
  //   </div>
  //   );


    className = "slider "+className;

    return (
      <section className={className}>
        {Children.map(newKids, (child, index) =>
          <div className={index == 0 ? 'row justify-content-center container current slide-'+(index+count) : 'container next slide-'+(index+count)}>

              {child}

          </div>
        )}
      </section>
    )

}

export default Slider
