import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './style.scss'
import { history } from '../history'

const Dash = () => {
  const data = {
    labels: ['Jul', 'Sep', 'Nov', 'Jan', 'Mar', 'May'],
    datasets: [
      {
        label: 'Total Donations',
        data: [0, 0, 0, 0, 0, 0],
        fill: false,
        borderColor: '#3e95cd'
      }
    ]
  };

  return (
    <Container className="dash">
      <Row className="my-4 next-steps">
        <Col>
          <h1>Dashboard</h1>
          <p>Welcome! This week you'll need to finish your Charity Index rating.</p>
          <Button variant="primary" onClick={function(){history.push("/setup-project")}}>+ Start a Project</Button>
        </Col>
      </Row>
      
    </Container>
  );
};

export default Dash;
