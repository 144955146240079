import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import './style.scss'

import Accordion from '../accordian'


const LoginForm = ({  children, className, redirect}) => {
  const user = Session.getUserData()

  // let welcomeClass = "welcome-info";
    if(!!className){
      className = "login-form "+className;
    }else{
      className = "login-form";
    }


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    Api.post({
      url: '/api/login',
      data: { email, password },
    }).then((res) => {
      if (!res.success || res.error || !res.user) {
        return setError(res.error || 'Invalid credentials')
      }

      Session.setUserData(res.user)
      history.push(redirect)
    })
  }

    return (
      <div className={className}>
        <form className="form" onSubmit={handleSubmit}>
          <Input type="text" value={email} onChange={setEmail} label={'Email'} />
          <Input
            type="password"
            value={password}
            onChange={setPassword}
            label={'Password'}
          />

          {error && <div className="error">{error}</div>}

          <Button
            className="form__button"
            value="Log in"
            onClick={handleSubmit}
          />
        </form> 
      </div>
    )
  
}

export default LoginForm
