import React from 'react'
import { history } from '../history'
import Session from '../session'
import './style.scss'

const Footer = () => {
  const user = Session.getUserData()

  const logout = () => {
    Session.logout()
    history.push('/')
  }

  let fRight ={
    float:"right"
  }

  return (
    <footer className="footer">
      <div className='content'>
      <div className='row'>
        <div className='col-4 footer-logo'>
        <a href="/" className="logo d-flex align-items-center" style={fRight}>
        <img src="/imgs/logo.png" alt=""/>
        {/* <img src="/assets/img/logo.png" alt=""> */}
        <span className="d-none d-lg-block">Cascade</span>
      </a>
        </div>
        <div className='col-8'>
          <div className='row'>
          <div className='col-3'>
          <b>Roles</b><br />
          <a href="/">Not-for-Profit</a><br />
          <a href="/">Charities</a><br />
          <a href="/">Businesses</a><br />
          <a href="/">Donators</a><br />
            </div>
            <div className='col-3'>
              
          <b>Company</b><br />
          <a href="/">About Us</a><br />
          <a href="/">Why we're different</a><br />
          <a href="/">Meet the team</a><br />
          <a href="/">Blog</a><br />
            </div>
            <div className='col-3'>
              
          <b>Support</b><br />
          <a href="/">FAQ</a><br />
          <a href="/">Contact Us</a><br />

            </div>
            <div className='col-3'>
            <b>Follow Us</b>
            </div>
            </div>
          
        </div>
      </div>
      <div className='row sub-footer'>
      <div className='col-3'>
        
      &copy; 2024 Cascade Pty LTD
        </div>
      <div className='offset-6 col-3'>
        <div className="links">
        <a href="/">Privacy Policy</a>
        <a href="/">Terms of Service</a>
        <a href="/">Cookies Settings</a>
        </div>
      </div>

      </div>
      </div>

    </footer>
  )
}

export default Footer
