import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Api from 'easy-fetch-api'
import AppWrapper from '../containers/app-wrapper'
import Slider from '../components/slider'
import WelcomeInfo from '../components/welcome-info'
import ProfileForm from '../components/profile-form'

import SliderPanel from '../components/slider-panel'
import QuestionnaireOverviewCard from '../components/questionnaire-overview'
import FinantialsForm from '../components/finantials-form'
import Button from '../components/button'

// import '../styles/questionnaire-finantials-page.scss'
import '../styles/pages/questionnaire-employment.scss'

import { useHistory } from "react-router-dom";


const QuestionnaireEmploymentPage = () => {
  const history = useHistory();

  const fakeImg = {
      color: "white",
      backgroundColor: "grey",
      padding: "10px",
      fontFamily: "Arial",
      // borderRadius: "10px"
      height : "600px",
      width : "600px"
    };

    const customButton={
      title:"Custom Button"
    };

    const inputStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px',
      border: '2px solid #ccc',
      borderRadius: '5px',
    };

    const previewStyle = {
      textAlign: 'center',
      margin: '20px auto',
    };

    const buttonStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px 20px',
      backgroundColor: '#28a745',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    };

    const circle={
      height: "100px",
      width: "100px",
      borderRadius: "50%",
      background: "#aaa"
    }

    const push={
      marginTop:"34%"
    }

    let redirect = false;


    const savedraft = (ev) => {
      ev.preventDefault()

      redirect  = true;
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

    const nextPage = (ev) => {
      ev.preventDefault()

      console.log(ev.target.value);
      console.log(this);

      if(!!ev.target.value){
        // redirect="";
        console.log(document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"]));
        document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"])[0].click();


        if(ev.target.value == "2023"){
          // document.getElementsByClassName(["questionaire-header"])[0].style.display = "none!important"
          setIsVisible(false);
        }
      }

    }

    const wip = (ev) => {
      // ev.preventDefault()


      console.log(ev);
      console.log(this);

      if(redirect){
        console.log("redirect to page...")
        history.push("/");
      }

      // document.getElementById("submit").click();


    }

    const [isVisible, setIsVisible] = useState(true);



    let rightStyle = {
      textAlign: "left"
    }

  return (
    <AppWrapper className="questionnaire-employment">
      <div className='header-title'>

        <div className='upper-title'>Charity Index Rating</div>
        <h1>Employment Practises</h1>
      </div>
      <div className="questionnaire-employment-page">
        <div className="container">

        {/* {isVisible && <div className="d-flex justify-content-between align-items-center questionaire-header">
          <h1>Finantial Ratios</h1>
          <div className="d-flex align-items-center">
            <button className="btn btn-secondary" onClick={savedraft}>save as draft</button>
          </div>
      </div>} */}




          <Slider className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 ">

          <SliderPanel
            className="col-lg-12"
            >
              <div className="align-middle" >
                <div className="">

                  <h1 style={rightStyle}>Intro</h1>
                  <p style={rightStyle}>
                    While our rating system incorporates financial ratios, it is condidered in conjunction with all other relevent information we gather from you. We recognise that genuine change cannot be accurately assessed solely through numerical metrics.
                  </p>
                  {/* <image src="imgs/logo.png" /> */}
                  <img src="/imgs/hero-1.png" alt=""></img>

                  <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage}>Next</Button>
              </div>
                </div>
              </div>

            </SliderPanel>


            <SliderPanel
            className="col-lg-12"
            >
                <span>1 of 3</span>
                  <h1>Policies</h1>
                  <p>Please provide a link or attachment for each of the following policies. We understand that each organisation may use different names or categories, and one policy may encompass several others. If this applies to your situation, please select ‘yes’ and upload one or multiple documents as required.</p>


                  <div className="upload-question">
                    <label>Privacy policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Whistle-blower policy</label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Conflict of Interest policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Related Part Transactions policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Records Retention and Destruction policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>
                  <div className="upload-question">
                    <label>Supplier policy </label>
                    <div className="d-flex align-items-center justify-content-between questions">
                      <div className='checkbox'>
                        <input type="checkbox" value="Surpus"  label={'Surpus'} />
                        <label>Surpus</label>
                      </div>
                      <div className='checkbox'>
                        <input type="checkbox" value="Deficit"  label={'Deficit'} />
                        <label>Deficit</label>
                      </div>

                      <div className='upload'>
                        <Button className="primary button sml">Attach pdf or link</Button>
                      </div>
                    </div>
                  </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} >Next</Button>
              </div>
            </SliderPanel>


            <SliderPanel
            className="col-lg-12 section-end"
            >
              <div className="align-middle" >
                <div className="section-end">
                  <img src="/imgs/journey.png" />
                  <h1>Nice! You’re 75% of the way through.</h1>
                  <p>
                    Thanks for submitting all your information. You can expect to hear from us in the next X days for your final rating.
                  </p>
                  <div className='d-flex align-items-center justify-content-between'>
                  <Button className="contact-us secondary green" onClick={function(){history.push("/")}}>Back</Button>
                  <Button className="next-module primary" onClick={function(){history.push("/questionnaire-adaptability")}}>Continue to 4. Adaptability</Button>
                  </div>
                </div>
              </div>

            </SliderPanel>


          </Slider>
        </div>
      </div>
    </AppWrapper>
  )
}

const getUrl = (url) => Api.get({ url })

export default QuestionnaireEmploymentPage
