import React from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header-nomenu'
import Footer from '../components/footer'
import Menu from '../components/menu'

import '../styles/containers/app-wrapper.scss'

function App({ children, className }) {

  let mainStyle = {
    marginLeft:0,
    padding:0
  };

  return (
    <div className={className}>
      <span className="divide-line" ></span>
      <Header />
      <div className="app">
       
        <Menu className="d-lg-none"/>
        <main className="main" id="main" style={mainStyle}>{children}</main>
      </div>
        <Footer />
    </div>
  )
}

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default App
