import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './style.scss'

const projectData = [
  { title: 'Christmas Appeal', amount: '$1,502 raised (40%)', image: '/imgs/project-default.png' },
  { title: 'Christmas Appeal', amount: '$1,502 raised (78%)', image: '/imgs/project-default.png' },
  { title: 'Christmas Appeal', amount: '$1,502 raised (16%)', image: '/imgs/project-default.png' }
];

const Project = () => {
  return (
    <Container className='project-tile'>
      <Row>
        <img src="/imgs/project-default.png" />
        <span>#1</span>
        <h2>Name Of Project</h2>
        <p>blah blah blah</p>

      </Row>
    </Container>
  );
};

export default Project;
