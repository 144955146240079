import React from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem ,Image } from 'react-bootstrap';
import './style.scss'
import Button from "../button"
import { history } from '../history'

let noLeft = {
  paddingLeft:"0px!important"
}

const GetIndex = () => {
  return (
    <div className="charity-index-rating container-fluid d-flex ">
      <div className="offset-6 text-left p-5 rounded" style={noLeft}>
        <h1>Get your Charity Index<sup>&#8482;	</sup> Rating</h1>
        <p>Impact overview lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
        <Button className="primary sml" onClick={function(){history.push("/questionnaire")}}>Get started</Button>
      </div>
    </div>
  );
};

export default GetIndex;
