import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Api from 'easy-fetch-api'
import AppWrapper from '../containers/app-wrapper'
import Slider from '../components/slider'
import WelcomeInfo from '../components/welcome-info'
import ProfileForm from '../components/profile-form'
import ProfileFormIntro from '../components/profile-form-intro'
import ProfileFormIndepth from '../components/profile-form-indepth'
import ProfileFormStory from '../components/profile-form-story'

import SliderPanel from '../components/slider-panel'
import ImageUpload from '../components/image-upload'
import '../styles/pages/setup-profile.scss'
import Button from '../components/button'

import { useHistory } from "react-router-dom";

const RegisterNfpPage = () => {

  const history = useHistory();
  let redirect = false;
  let editState = false;
  let header = "Create Account";
  let upperHeader = "Get Started";

  
  let goto = "/";

  if(window.location.hash === "#edit"){
    editState = true;
    header = "Edit Account";
    upperHeader = "";
  }

  const [isVisible, setIsVisible] = useState(editState);

  

  const fakeImg = {
      color: "white",
      backgroundColor: "grey",
      padding: "10px",
      fontFamily: "Arial",
      // borderRadius: "10px"
      height : "600px",
      width : "600px"
    };

    const customButton={
      title:"Custom Button"
    };

    const inputStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px',
      border: '2px solid #ccc',
      borderRadius: '5px',
    };

    const previewStyle = {
      textAlign: 'center',
      margin: '20px auto',
    };

    const buttonStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '10px 20px',
      backgroundColor: '#28a745',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    };


    const savedraft = (ev) => {
      ev.preventDefault()

      redirect  = true;
      goto = "/";
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

    const nextPage = (ev) => {
      ev.preventDefault()

      console.log(ev.target.value);
      console.log(this);

      if(!!ev.target.value){
        // redirect="";
        console.log(document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"]));
        document.getElementsByClassName([ev.target.value])[0].getElementsByClassName(["submit"])[0].click();


        if(ev.target.value == "2023"){
          // document.getElementsByClassName(["questionaire-header"])[0].style.display = "none!important"
          setIsVisible(false);
        }
      }

    }

    const wip = (ev, redirected) => {
      // ev.preventDefault()


      console.log(ev);
      console.log(this);
      console.log(redirected);

      if(redirect){
        console.log("redirect to page...")
        history.push(goto);
      }

      // document.getElementById("submit").click();


    }
    

    const preview = (ev) => {
      ev.preventDefault()

      redirect  = true;
      goto = "/nfp-profile";
      document.getElementsByClassName(["current"])[0].getElementsByClassName(["submit"])[0].click()


    }

  return (
    <AppWrapper className="setup-profile">
      <div className='header-title'>
        <div className='upper-title'>{upperHeader}</div>
        <h1>{header}</h1>
      </div>


      <div className="setup-profile-page">
        <div className="container">
          <Slider className="section register  d-flex flex-column align-items-center justify-content-center " redirect="/nfp-profile" hideSlide={isVisible}>

<WelcomeInfo
            className="col-lg-9"
            title="Finish setting up your profile"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun."
            next
            >
              <div className="fake-image" style={{borderRadius: "50%"}} />
            </WelcomeInfo>
            

            <SliderPanel className="row intro" >

            <div className="col-lg-8">
              <span>1 of 3</span>
              <h2>Your Intro</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              <div className="row">
                <ProfileFormIntro  callback={wip} name="The Smith Family" location="Australia"/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="secondary lrg" onClick={savedraft}>Save as draft</Button>
                <Button className="secondary lrg" onClick={preview}>Preview</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="intro">Next</Button>
              </div>
            </div>
            </SliderPanel>

            <SliderPanel className="row story" >

            <div className="col-lg-8">
              <span>2 of 3</span>
              <h2>Your Story</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              <div className="row">
                <ProfileFormStory title="Finantial Ratios" progress={30} buttonLink="/finantial-ratios" year="2021" redirect={redirect} callback={wip} name="The Smith Family" location="Australia"/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="secondary lrg" onClick={savedraft}>Save as draft</Button>
                <Button className="secondary lrg"onClick={preview}>Preview</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="story">Next</Button>
              </div>
            </div>
            </SliderPanel>

            <SliderPanel className="row depth" >

            <div className="col-lg-8">
              <span>3 of 3</span>
              <h2>In Depth</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              <div className="row">
                <ProfileFormIndepth title="Finantial Ratios" progress={30} buttonLink="/finantial-ratios" year="2021" redirect={"redirect"} callback={wip} name="The Smith Family" location="Australia"/>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Button className="secondary prev-btn lrg">back</Button>
                <Button className="secondary lrg" onClick={savedraft}>Save as draft</Button>
                <Button className="secondary lrg"onClick={preview}>Preview</Button>
                <Button className="primary button next-btn lrg" onClick={nextPage} value="depth">Next</Button>
              </div>
            </div>
            </SliderPanel>






          </Slider>
        </div>
      </div>
    </AppWrapper>
  )
}

const getUrl = (url) => Api.get({ url })

export default RegisterNfpPage
