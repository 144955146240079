import React, { useEffect, useState } from 'react';
import { Children } from 'react';
import Api from 'easy-fetch-api'
import Button from '../button'
import Input from '../input'


import { history, getPrevLocation } from '../history'
import Session from '../session'
import FileUpload from '../file-upload'
import ImageUpload from '../image-upload'



import './style.scss'

import Accordion from '../accordian'



const ProjectFormImpact = ({  children, className, redirect, year, callback, name, location , projectId}) => {
  const user = Session.getUserData()
  const charity = Session.getCharityData()

  console.log(charity);


  // let welcomeClass = "welcome-info";
    if(!!charity){
      className = "profile-form-intro "+projectId;
    }else{
      className = "profile-form-intro";
    }


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)


  const [goal1, setgoal1] = useState('a')
  const [goal2, setgoal2] = useState('b')
  const [goal3, setgoal3] = useState('c')
  const [goal4, setgoal4] = useState('d')
  const [impact1image, setimpact1image] = useState('https://cascadepublic.blob.core.windows.net/img/1719758989973.png')
  const [impact2image, setimpact2image] = useState('https://cascadepublic.blob.core.windows.net/img/1719758989973.png')
  const [impact3image, setimpact3image] = useState('https://cascadepublic.blob.core.windows.net/img/1719758989973.png')  
  const [impact4image, setimpact4image] = useState('https://cascadepublic.blob.core.windows.net/img/1719758989973.png')

  const [impact1amount, setimpact1amount] = useState('1')
  const [impact2amount, setimpact2amount] = useState('2')
  const [impact3amount, setimpact3amount] = useState('3')  
  const [impact4amount, setimpact4amount] = useState('4')

  const [impact1outcome, setimpact1outcome] = useState('11')
  const [impact2outcome, setimpact2outcome] = useState('22')
  const [impact3outcome, setimpact3outcome] = useState('33')
  const [impact4outcome, setimpact4outcome] = useState('44')



  // let charityId = "1"
  let editedBy = "default";

  if(!!user){
    editedBy = user._id;
  }


  


  // if(!charity){
  //   charity = "Charity 1";
  // }


  const fetchData = async () => {
    // setLoading(true);
    // setError(null);

    try {
      const response = await fetch('/api/project-impact/'+projectId);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();


      // setData(result);
      console.log(result);
      setgoal1(result.projectGoals.goal1)
      setgoal2(result.projectGoals.goal2)
      setgoal3(result.projectGoals.goal3)
      setgoal4(result.projectGoals.goal4)

      setimpact1image(result.projectGoals.impact1image)
      setimpact2image(result.projectGoals.impact2image)
      setimpact3image(result.projectGoals.impact3image)
      setimpact4image(result.projectGoals.impact4image)

      setimpact1amount(result.projectGoals.impact1amount)
      setimpact2amount(result.projectGoals.impact2amount)
      setimpact3amount(result.projectGoals.impact3amount)
      setimpact4amount(result.projectGoals.impact4amount)

      setimpact1outcome(result.projectGoals.impact1outcome)
      setimpact2outcome(result.projectGoals.impact2outcome)
      setimpact3outcome(result.projectGoals.impact3outcome)
      setimpact4outcome(result.projectGoals.impact4outcome)

      // setTotalRevenue(result.financial.totalrevenue)
      // setTotalProgramExpenses(result.financial.totalprogramexpenses)
      // setTotalFundraisingExpenses(result.financial.totalfundraisingexpenses)
      // setTotalAssets(result.financial.totalassets)
      // setTotalLiabilities(result.financial.totalliabilities)
      // setTotalDonationsRecived(result.financial.totaldonationsrecived)
      // setTotalAdministrativeExpenses(result.financial.totaladministrativeexpenses)
      // setCurrentAssets(result.financial.currentassets)
      // setCurrentLiabilities(result.financial.currentliabilities)


    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };

  // useEffect(() => {
  //   if(!!projectId){
  //     fetchData();
  //   }
    
  // }, [projectId]);

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setError(null)

    console.log("***");
    console.log(user);
    console.log(editedBy);


    let registeredCharityNumber = charity.registeredCharityNumber;


    // callback();
    Api.post({
      url: '/api/project-impact',
      data: { projectId, goal1, goal2, goal3, goal4, impact1image, impact2image, impact3image, impact4image, impact1amount, impact2amount, impact3amount, impact4amount, impact1outcome, impact2outcome, impact3outcome, impact4outcome },
    }).then((res) => {
      if (!res.success || res.error ) {
        return setError(res.error || 'Invalid details')
      }
      
      callback(res,redirect);
      // history.push(redirect)
    })
  }

  let inputStyle = {};
  let previewStyle = {};
  let buttonStyle = {};


    return (
      <div className={className}>
        <form onSubmit={handleSubmit} id="theForm">
        <div className="row justify-content-center">{projectId}
          <div className="col-lg-6">
            <Input type="text" value={goal1} onChange={setgoal1} label={'Goal 1'} />
          </div>
          <div className="col-lg-6">
            <Input type="text" value={goal2} onChange={setgoal2} label={'Goal 2'} />
          </div>
          <div className="col-lg-6">
            <Input type="text" value={goal3} onChange={setgoal3} label={'Goal 3'} />
          </div>
          <div className="col-lg-6">
            <Input type="text" value={goal4} onChange={setgoal4} label={'Goal 4'} />
          </div>
          <div class="input-wrapper">
            <div class="label-error-wrapper">
              <label class="input-name-label">Description</label>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <div class="wrapper__prefix row justify-content-center">
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimpact1image}
                  value={impact1image}
                />
              </div>
              <div className="col-lg-4">
                <Input type="text" value={impact1amount} onChange={setimpact1amount} label={'Donated amount'} />
              </div>
              <div className="col-lg-4">
                <Input type="text" value={impact1outcome} onChange={setimpact1outcome} label={'Tangible outcome'} />
              </div>
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimpact2image}
                  value={impact2image}
                />
              </div>
              <div className="col-lg-4">
                <Input type="text" value={impact2amount} onChange={setimpact2amount} label={'Donated amount'} />
              </div>
              <div className="col-lg-4">
                <Input type="text" value={impact2outcome} onChange={setimpact2outcome} label={'Tangible outcome'} />
              </div>
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimpact3image}
                  value={impact3image}
                />
              </div>
              <div className="col-lg-4">
                <Input type="text" value={impact3amount} onChange={setimpact3amount} label={'Donated amount'} />
              </div>
              <div className="col-lg-4">
                <Input type="text" value={impact3outcome} onChange={setimpact3outcome} label={'Tangible outcome'} />
              </div>
              
              <div className="col-lg-4">
                <ImageUpload
                  uploadUrl="/upload"
                  inputStyle={inputStyle}
                  previewStyle={previewStyle}
                  buttonStyle={buttonStyle}
                  className="cover-img"
                  onChange={setimpact4image}
                  value={impact4image}
                />
              </div>
              <div className="col-lg-4">
                <Input type="text" value={impact4amount} onChange={setimpact4amount} label={'Donated amount'} />
              </div>
              <div className="col-lg-4">
                <Input type="text" value={impact4outcome} onChange={setimpact4outcome} label={'Tangible outcome'} />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4">
            <ImageUpload
              uploadUrl="/upload"
              inputStyle={inputStyle}
              previewStyle={previewStyle}
              buttonStyle={buttonStyle}
              className="cover-img"
              onChange={setimage6}
              value={image6}
            />
          </div> */}


            <button className="submit" onClick={handleSubmit} > submit </button>
          </div>
        </form>
      </div>
    )

}

export default ProjectFormImpact
