import React from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem ,Image } from 'react-bootstrap';
import './style.scss'
import Button from '../button';

const reportData = [
  { title: 'Financial report', date: 'Jul \'23 – Jun \'24', link: '#' },
  { title: 'Marketing report', date: 'May \'24', link: '#' },
  { title: 'HR report', date: 'Nov \'23 – Jan \'24', link: '#' },
  { title: 'Financial report', date: 'Jul \'23 – Jun \'24', link: '#' }
];
let nopad={
  padding:"0px"
}
const Reports = () => {
  return (
    <Container style={nopad}>
      {/* <Row className="my-4"> */}
          <h2>Reports</h2>
          <ListGroup>
          {reportData.map((report, index) => (
              <ListGroupItem key={index}>
                <div className='content'>
                  
                  <div className="name"><i className="bi bi-grid"></i><b>{report.title}</b></div>
                  <span>{report.date}</span>
                  <Button className="secondary sml green">View</Button>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
      {/* </Row> */}
    </Container>

    // <Container>
    //   <Row className="my-4">
    //     <Col>
    //       <h2>Reports</h2>
    //       <ListGroup>
    //         {reportData.map((report, index) => (
    //           <ListGroup.Item action href={report.link} key={index}>
    //             <h5>{report.title}</h5>
    //             <p>{report.date}</p>
    //           </ListGroup.Item>
    //         ))}
    //       </ListGroup>
    //     </Col>
    //   </Row>
    // </Container>
  );
};

export default Reports;
