import React, { useEffect } from 'react';
import { history } from '../history'
import Session from '../session'
import './style.scss'
import { Button } from 'react-bootstrap';

import LoginHeaderWidget from '../login-header-widget'


const Header = (noMenu) => {
  const user = Session.getUserData()

  const logout = () => {
    Session.logout()
    history.push('/')
  }

  const login = () => {
    history.push('/login')
  }

  let linkLeft = {
    marginLeft:"20px"
  }


  useEffect(() => {
    const toggleButton = document.querySelector('.toggle-sidebar-btn');
    const body = document.querySelector('body');

    const handleToggleSidebar = () => {
      if (window.innerWidth <= 1200) {
        body.classList.toggle('toggle-sidebar');
      }
    };

    if (toggleButton) {
      toggleButton.addEventListener('click', handleToggleSidebar);
    }

    const handleResize = () => {
      if (window.innerWidth > 1200) { // Replace 768 with your mobile breakpoint
        body.classList.remove('toggle-sidebar');
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

  });

  

  return (
      <header id="header" className="header fixed-top d-flex align-items-center">
    <div className="d-flex align-items-center justify-content-between">

    

      <i className="bi bi-list toggle-sidebar-btn"></i>


      {/* <i className="bi bi-list toggle-sidebar-btn"></i> */}
      <a href="/" className="logo d-flex align-items-center" style={{linkLeft}}>
        <img src="/imgs/logo.png" alt=""/>
        {/* <img src="/assets/img/logo.png" alt=""> */}
        <span className="d-none d-lg-block">Cascade</span>
      </a>
    </div>
{/* 
    <div className="search-bar">
      <form className="search-form d-flex align-items-center" method="POST" action="#">
        <input type="text" name="query" placeholder="Search" title="Enter search keyword">
        <button type="submit" title="Search"><i className="bi bi-search"></i></button>
      </form>
    </div>
*/}
    <nav className="header-nav">
      <ul className="d-flex align-items-center">

      <li className="nav-item d-block">
          <a className="nav-link nav-icon" href="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9 21.0002V13.6002C9 13.0402 9 12.7601 9.10899 12.5462C9.20487 12.3581 9.35785 12.2051 9.54601 12.1092C9.75992 12.0002 10.0399 12.0002 10.6 12.0002H13.4C13.9601 12.0002 14.2401 12.0002 14.454 12.1092C14.6422 12.2051 14.7951 12.3581 14.891 12.5462C15 12.7601 15 13.0402 15 13.6002V21.0002M11.0177 2.76424L4.23539 8.03937C3.78202 8.39199 3.55534 8.5683 3.39203 8.7891C3.24737 8.98469 3.1396 9.20503 3.07403 9.4393C3 9.70376 3 9.99094 3 10.5653V17.8002C3 18.9203 3 19.4804 3.21799 19.9082C3.40973 20.2845 3.71569 20.5905 4.09202 20.7822C4.51984 21.0002 5.07989 21.0002 6.2 21.0002H17.8C18.9201 21.0002 19.4802 21.0002 19.908 20.7822C20.2843 20.5905 20.5903 20.2845 20.782 19.9082C21 19.4804 21 18.9203 21 17.8002V10.5653C21 9.99094 21 9.70376 20.926 9.4393C20.8604 9.20503 20.7526 8.98469 20.608 8.7891C20.4447 8.5683 20.218 8.39199 19.7646 8.03937L12.9823 2.76424C12.631 2.49099 12.4553 2.35436 12.2613 2.30184C12.0902 2.2555 11.9098 2.2555 11.7387 2.30184C11.5447 2.35436 11.369 2.49099 11.0177 2.76424Z"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
            Home
          </a>
        </li>
        {!!user?(
        <li className="nav-item d-block">
          <a className="nav-link nav-icon" href="/dashboard">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9 21.0002V13.6002C9 13.0402 9 12.7601 9.10899 12.5462C9.20487 12.3581 9.35785 12.2051 9.54601 12.1092C9.75992 12.0002 10.0399 12.0002 10.6 12.0002H13.4C13.9601 12.0002 14.2401 12.0002 14.454 12.1092C14.6422 12.2051 14.7951 12.3581 14.891 12.5462C15 12.7601 15 13.0402 15 13.6002V21.0002M11.0177 2.76424L4.23539 8.03937C3.78202 8.39199 3.55534 8.5683 3.39203 8.7891C3.24737 8.98469 3.1396 9.20503 3.07403 9.4393C3 9.70376 3 9.99094 3 10.5653V17.8002C3 18.9203 3 19.4804 3.21799 19.9082C3.40973 20.2845 3.71569 20.5905 4.09202 20.7822C4.51984 21.0002 5.07989 21.0002 6.2 21.0002H17.8C18.9201 21.0002 19.4802 21.0002 19.908 20.7822C20.2843 20.5905 20.5903 20.2845 20.782 19.9082C21 19.4804 21 18.9203 21 17.8002V10.5653C21 9.99094 21 9.70376 20.926 9.4393C20.8604 9.20503 20.7526 8.98469 20.608 8.7891C20.4447 8.5683 20.218 8.39199 19.7646 8.03937L12.9823 2.76424C12.631 2.49099 12.4553 2.35436 12.2613 2.30184C12.0902 2.2555 11.9098 2.2555 11.7387 2.30184C11.5447 2.35436 11.369 2.49099 11.0177 2.76424Z"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Dashboard
          </a>
        </li>

        ):(null)}
        <li className="nav-item d-block">
          <a className="nav-link nav-icon" href="/">
            <i className="bi bi-search"></i>
            Discover
          </a>
        </li>


      </ul>
    </nav> 

    <LoginHeaderWidget redirect="/dashboard"/>
{/* 
      {!!user ? (
        <div className="header__user">
        <Button variant="primary notifications"><i className="bi bi-search"></i></Button>
        <Button variant="primary user"><i className="bi bi-search"></i> {user.name}</Button>

        </div>
      ) : <div className="header__user">
            <Button variant="primary user" onClick={login}><i className="bi bi-search"></i> Login</Button>
        </div>} */}
    </header>
  )
}

export default Header
